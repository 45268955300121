<template>
<div class="newpet">
    <v-app-bar app flat color="white">

        <v-btn icon :to="'/home'">
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>Add Your Pet</v-toolbar-title>

        <template v-slot:extension>
            <v-row justify="center" no-gutters>
                <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">
                    <div class="pl-2 pb-1 font-weight-bold">Step {{step}} of 7</div>
                    <v-row no-gutters>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 0 ? 'primary' : '#ccc'" :value="step > 0 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 1 ? 'primary' : '#ccc'" :value="step > 1 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 2 ? 'primary' : '#ccc'" :value="step > 2 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 3 ? 'primary' : '#ccc'" :value="step > 3 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 4 ? 'primary' : '#ccc'" :value="step > 4 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 5 ? 'primary' : '#ccc'" :value="step > 5 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                        <v-col class="px-2">
                            <v-progress-linear :color="step > 6 ? 'primary' : '#ccc'" :value="step > 6 ? 100 : 0" rounded height="6"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </template>

    </v-app-bar>

    <div>
        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">

                <div v-show="step == 1" class="px-4 py-4">

                    <div class="primary--text text-subtitle-1 font-weight-medium pb-4">What type of pet do you have?</div>

                    <v-item-group v-model="pet_type" mandatory @change="breed1=''; breed2=''">
                        <!-- <v-row>
                            <v-col cols="4" class="d-flex align-center justify-center" v-for="(item,index) in this.$store.getters.petTypes" :key="index">
                                <v-item v-slot="{ active, toggle }" :value="item">
                                    <v-sheet @click="toggle" height="100" width="100" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center pa-1">
                                           <v-icon size="56">{{'$vuetify.icons.'+item.icon}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            {{item.type}}
                                        </div>
                                    </v-sheet>
                                </v-item>
                            </v-col>
                        </v-row> -->

                        <v-row>
                            <v-col cols="6" class="d-flex align-center justify-center" v-for="(item,index) in this.$store.getters.petTypes" :key="index">
                                <v-item v-slot="{ active, toggle }" :value="item">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <!-- <v-icon size="36">{{item.icon}}</v-icon> -->
                                            <v-icon size="56">{{'$vuetify.icons.'+item.icon}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            {{item.type}}
                                        </div>
                                    </v-sheet>
                                </v-item>
                            </v-col>
                        </v-row>

                    </v-item-group>

                    <div class="primary--text text-subtitle-1 font-weight-medium pb-4 pt-6">What do you want for your pet?</div>

                    <v-item-group v-model="purpose">
                        <v-row v-if="this.$store.getters.location != 'IN'">
                            <v-col cols="6" class="d-flex flex-column align-center justify-start">
                                <v-item v-slot="{ active, toggle }" value="lover">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <v-icon size="56">{{'$vuetify.icons.lover-icon'}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Lover
                                        </div>
                                    </v-sheet>
                                </v-item>
                                <span class="text-caption pa-2 px-0 text-center grey--text">Need a Mating Partner</span>
                                
                            </v-col>
                            <v-col cols="6" class="d-flex flex-column align-center justify-start">
                                <v-item v-slot="{ active, toggle }" value="friend">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <v-icon size="56">{{'$vuetify.icons.friend-icon'}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Friend
                                        </div>
                                    </v-sheet>
                                </v-item>
                                <span class="text-caption pa-2 px-0 text-center grey--text">Need a Playmate</span>
                            </v-col>
                            <v-col cols="6" class="d-flex flex-column align-center justify-start">
                                <v-item v-slot="{ active, toggle }" value="adopter">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <v-icon size="56">{{'$vuetify.icons.adopter-icon'}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Adopter
                                        </div>
                                    </v-sheet>
                                </v-item>
                                <span class="text-caption pa-2 px-0 text-center grey--text">Need an Adopter</span>
                            </v-col>
                            <v-col cols="6" class="d-flex flex-column align-center justify-start">
                                <v-item v-slot="{ active, toggle }" value="buyer">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <v-icon size="56">{{'$vuetify.icons.buyer-icon'}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Buyer
                                        </div>
                                    </v-sheet>
                                </v-item>
                                <span class="text-caption pa-2 px-0 text-center grey--text">Need a Buyer</span>
                            </v-col>
                        </v-row>


                        <v-row v-else>
                            <v-col cols="6" class="d-flex flex-column align-center justify-start">
                                <v-item v-slot="{ active, toggle }" value="lover">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <v-icon size="56">{{'$vuetify.icons.lover-icon'}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Lover
                                        </div>
                                    </v-sheet>
                                </v-item>
                                <span class="text-caption pa-2 px-0 text-center grey--text">Need a Mating Partner</span>
                                
                            </v-col>
                            <v-col cols="6" class="d-flex flex-column align-center justify-start">
                                <v-item v-slot="{ active, toggle }" value="friend">
                                    <v-sheet @click="toggle" height="110" width="110" class="pa-3 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-1">
                                            <v-icon size="56">{{'$vuetify.icons.friend-icon'}}</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Friend
                                        </div>
                                    </v-sheet>
                                </v-item>
                                <span class="text-caption pa-2 px-0 text-center grey--text">Need a Playmate</span>
                            </v-col>
                            
                        </v-row>

                    </v-item-group>

                </div>

                <div v-show="step == 2" class="px-4 py-4">

                    <div class="primary--text text-subtitle-1 font-weight-medium ">Name of your pet?</div>

                    <v-text-field solo flat hide-details placeholder="Your pet's name" v-model="name" autofocus></v-text-field>

                    <div class="primary--text text-subtitle-1 font-weight-medium pt-6 pb-4">Boy or Girl?</div>

                    <v-item-group v-model="gender" mandatory>
                        <v-row>
                            <v-col cols="4" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="M">
                                    <v-sheet @click="toggle" height="100" width="100" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-3">
                                            <v-icon size="36">mdi-gender-male</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Male
                                        </div>
                                    </v-sheet>
                                </v-item>
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="F">
                                    <v-sheet @click="toggle" height="100" width="100" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <!-- <v-icon :color="active ? 'white' : 'primary'">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon> -->
                                        <div class="text-center pa-3">
                                            <v-icon size="36">mdi-gender-female</v-icon>
                                        </div>
                                        <div class="text-center">
                                            Female
                                        </div>
                                    </v-sheet>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                </div>

                <div v-show="step == 3" class="px-4 py-4">

                    <div class="primary--text text-subtitle-1 font-weight-medium  ">What's the breed of {{name}}?</div>

                    <v-checkbox v-model="purebred" hide-details label="Purebred"></v-checkbox>

                    <v-checkbox v-model="crossbred" label="Crossbred(Mixed Breed)"></v-checkbox>

                    <v-autocomplete v-model="breed1" hide-no-data :filter="filterObject" :items="typeBreeds" item-text="breed" item-value="xid" label="Primary Breed" return-object>

                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>

                    </v-autocomplete>

                    <v-autocomplete v-show="crossbred && breed1" v-model="breed2" :items="typeBreeds" item-text="breed" item-value="xid" label="Secondary Breed" return-object>

                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>

                    </v-autocomplete>

                    <v-row no-gutters class="pt-4">
                            <v-col cols="8">
                                <div class="primary--text text-subtitle-1 font-weight-medium pt-2">Pedigree Certified</div>
                            </v-col>
                            <v-col>
                                <v-radio-group v-model="pedigree_certified" class="mt-2" >
                                    <v-radio label="Yes" :value="1"  ></v-radio>
                                    <v-radio label="No" :value="0" class="pt-1" ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="">
                            <v-col cols="8">
                                <div class="primary--text text-subtitle-1 font-weight-medium pt-2">DNA Tested</div>
                            </v-col>
                            <v-col>
                                <v-radio-group v-model="dna_tested" class="mt-2" >
                                    <v-radio label="Yes" :value="1"  ></v-radio>
                                    <v-radio label="No" :value="0" class="py-1" ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="">
                            <v-col cols="8">
                                <div class="primary--text text-subtitle-1 font-weight-medium pt-2">Vaccinated</div>
                            </v-col>
                            <v-col>
                                <v-radio-group v-model="vaccinated" class="mt-2" >
                                    <v-radio label="Yes" :value="1"  ></v-radio>
                                    <v-radio label="No" :value="0" class="py-1" ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <div v-if="purpose != 'lover'">

                        <v-row no-gutters class="">
                            <v-col cols="8">
                                <div class="primary--text text-subtitle-1 font-weight-medium pt-2">Neutered</div>
                            </v-col>
                            <v-col>
                                <v-radio-group v-model="neutered" class="mt-2" >
                                    <v-radio label="Yes" :value="1"  ></v-radio>
                                    <v-radio label="No" :value="0" class="py-1" ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        </div>

                        <div v-if="purpose == 'lover'">
                        <v-row no-gutters class="">
                            <v-col cols="8">
                                <div class="primary--text text-subtitle-1 font-weight-medium pt-2">Breeding for the first time</div>
                            </v-col>
                            <v-col>
                                <v-radio-group v-model="mating_first_time" class="mt-2" >
                                    <v-radio label="Yes" :value="1"  ></v-radio>
                                    <v-radio label="No" :value="0" class="py-1" ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </div>




                   <!--  <div class="primary--text text-subtitle-1 font-weight-medium pt-6">Pedigree Certified</div>

                     <v-radio-group v-model="pedigree_certified" class="mt-2 mb-4" row>
                        <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
                        <v-radio label="No" :value="0"  class="py-2"></v-radio>
                    </v-radio-group>

                    <div class="primary--text text-subtitle-1 font-weight-medium">DNA Tested</div>

                     <v-radio-group v-model="dna_tested" class="mt-2 mb-4" row>
                        <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
                        <v-radio label="No" :value="0"  class="py-2"></v-radio>
                    </v-radio-group> -->

                    

                    

                </div>

                <div v-show="step == 4" class="px-4 py-4">

                    <div class="primary--text text-subtitle-1 font-weight-medium">How big is {{name}}</div>

                     <v-radio-group v-model="size" class="mt-2 mb-4" row>
                        <v-radio :label="item.size" :value="item.xid" v-for="(item,index) in this.$store.getters.petSizes" :key="index" class="py-2"></v-radio>
                    </v-radio-group>


                    <div class="primary--text text-subtitle-1 font-weight-medium ">How old is {{name}}?</div>

                    <v-row class="mt-1">
                        <v-col cols="6">
                            <v-text-field v-model="age_years"  outlined type="number" placeholder="0" :suffix="age_years > 1 ? 'years' : 'year'" :rules="yearRules"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field v-model="age_months"  outlined type="number" placeholder="0" :suffix="age_months > 1 ? 'months' : 'month'" :rules="monthRules"></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="primary--text text-subtitle-1 font-weight-medium ">What's {{gender=='M' ? 'his' : 'her'}} weight?</div>

                    <v-row class="mt-1">
                        <v-col cols="6">
                            <v-text-field v-model="weight_number"  outlined type="number" placeholder="0" :rules="weightRules"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="weight_unit"
                             outlined  hide-details
                                :items="['lbs', 'kgs']">
                            </v-select>
                        </v-col>
                    </v-row>

                    <div class="text-overline grey--text">Behaviour</div>
                    <v-divider class="mb-4"></v-divider>

                    <div class="primary--text text-subtitle-1 font-weight-medium">Good with Kids?</div>

                     <v-radio-group v-model="good_with_kids" class="mt-2 mb-4" row>
                        <v-radio label="Yes" :value="1" class="py-2"></v-radio>
                        <v-radio label="No" :value="0" class="py-2"></v-radio>
                        <v-radio label="Dont Know" :value="''" class="py-2"></v-radio>
                    </v-radio-group>

                    <div class="primary--text text-subtitle-1 font-weight-medium">Good with Cats?</div>

                     <v-radio-group v-model="good_with_cats" class="mt-2 mb-4" row>
                        <v-radio label="Yes" :value="1" class="py-2"></v-radio>
                        <v-radio label="No" :value="0" class="py-2"></v-radio>
                        <v-radio label="Dont Know" :value="''" class="py-2"></v-radio>
                    </v-radio-group>

                    <div class="primary--text text-subtitle-1 font-weight-medium">Good with Dogs?</div>

                     <v-radio-group v-model="good_with_other_dogs" class="mt-2 mb-4" row>
                        <v-radio label="Yes" :value="1" class="py-2"></v-radio>
                        <v-radio label="No" :value="0" class="py-2"></v-radio>
                        <v-radio label="Dont Know" :value="''" class="py-2"></v-radio>
                    </v-radio-group>

                    <div class="primary--text text-subtitle-1 font-weight-medium">Potty Trained?</div>

                     <v-radio-group v-model="potty_trained" class="mt-2 mb-4" row>
                        <v-radio label="Yes" :value="1" class="py-2"></v-radio>
                        <v-radio label="No" :value="0" class="py-2"></v-radio>
                    </v-radio-group>

                </div>

                <div v-show="step == 5" class="px-4 py-4">

                    <div v-if="purpose == 'buyer'">

                    <div class="primary--text text-subtitle-1 font-weight-medium">What's {{gender=='M' ? 'his' : 'her'}} price?</div>

                    <v-row class="mt-1">
                        <v-col cols="6">
                            <v-text-field v-model="price" outlined type="number" placeholder="0" :rules="priceRules"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="price_ccy"
                             outlined hide-details
                                :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                            </v-select>
                        </v-col>
                    </v-row>

                    </div>

                    <div v-if="purpose == 'adopter'">

                    <div class="primary--text text-subtitle-1 font-weight-medium">What's the adoption fee?</div>

                    <v-row class="mt-1">
                        <v-col cols="6">
                            <v-text-field v-model="adoption_fees" outlined type="number" placeholder="0" :rules="priceRules"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="adoption_fees_ccy"
                            outlined hide-details
                                :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                            </v-select>
                        </v-col>
                    </v-row>

                    </div>

                    <div v-if="purpose == 'lover' && gender == 'M'">

                    <div class="primary--text text-subtitle-1 font-weight-medium">What's the stud fee?</div>

                    <v-row class="mt-1">
                        <v-col cols="6">
                            <v-text-field v-model="stud_fees" outlined type="number" placeholder="0" :rules="priceRules"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="stud_fees_ccy"
                            outlined hide-details
                                :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                            </v-select>
                        </v-col>
                    </v-row>

                    </div>

                    <div class="primary--text text-subtitle-1 font-weight-medium">Instagram handle of {{name}} (optional)</div>

                    
                       <v-text-field v-model="insta_handle" solo flat  placeholder="instagram" prefix="@"></v-text-field>
                      

                    <div class="primary--text text-subtitle-1 font-weight-medium">Tell us more about {{name}}?</div>

                    <v-textarea autofocus solo flat auto-grow row-height="40" rows="5" placeholder="Your pet's temperament, character and what are you looking for" v-model="description"></v-textarea>

                </div>

                <div v-show="step == 6" class="px-4 py-4">

                    <!-- <div class="primary--text text-subtitle-1 font-weight-medium">Your pet's location?</div>

                    <v-alert v-model="locationPicked" class="pl-0">
                        <v-row align="center">
                            <v-col class="grow">
                                {{location ? location.description : ''}}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn icon @click="clearLocation">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>

                    <div v-show="!locationPicked">

                        <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat hide-no-data placeholder="Please type your locality" item-text="description" item-value="placeId" clearable autofocus hint="So that we can find matches closer to you" persistent-hint prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails" @click:clear="clearLocation"></v-autocomplete>

                        <div class="py-4">
                            <v-btn text small color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                                <v-icon left>mdi-crosshairs-gps</v-icon> {{gettingLocation ? 'Getting your location' : 'get your current location'}}
                            </v-btn>
                        </div>

                    </div>
 -->

                    <div v-if="purpose == 'lover' || purpose == 'friend'">
                        <div class="primary--text text-subtitle-1 font-weight-medium mt-4">Willingness to travel</div>
                        <v-textarea autofocus solo flat auto-grow row-height="30" rows="4"   placeholder="How far can you travel for meetings?" v-model="willingness_to_travel"></v-textarea>
                    </div>

                    <div v-if="purpose == 'lover'">
                        <div class="primary--text text-subtitle-1 font-weight-medium mt-4">Breeding Terms</div>
                        <v-textarea autofocus solo flat auto-grow row-height="30" rows="4"   placeholder="Describe any special conditions you require for breeding." v-model="breeding_terms"></v-textarea>
                    </div>

                    <div v-if="purpose == 'lover'">
                        <div class="primary--text text-subtitle-1 font-weight-medium mt-4">Accommodation Responsibility</div>
                        <v-textarea autofocus solo flat auto-grow row-height="30" rows="4"  placeholder="Will you arrange accommodation, or should the other party?" v-model="accommodation_responsibility"></v-textarea>
                    </div>
                    
                    <div v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-1 font-weight-medium mt-4">Preferred payment method</div>
                        <v-textarea autofocus solo flat auto-grow row-height="30" rows="4"  placeholder="Do you prefer cash or online methods?" v-model="preferred_payment_method"></v-textarea>
                    </div>
                    

                </div>

                <div v-show="step == 7" class="px-4 py-4">

                    <div class="primary--text text-subtitle-1 font-weight-medium">Add some photos of {{name}}</div>

                    <v-row no-gutters align="center" class="pt-6">

                      <v-col cols="12" class="px-6 text-center d-flex justify-center ">

                      <v-img v-if="petAvatar" :src="petAvatar" cover style="border-radius:60px;" max-width="120" aspect-ratio="1">
                                <v-btn icon dark @click="deleteAvatar">
                                    <v-icon >
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-img>

                            <v-card v-else flat style="border-radius:60px; border: 2px dashed #adb5bd; width: 120px; height: 120px;" @click="launchAvatarPicker">
                                <v-icon class="pa-8" color="primary" size="36">mdi-image-plus</v-icon>
                            </v-card>

                           

                            <input type="file" ref="avatarpic" name="avatarpic" @change="loadAvatar($event)" style="display:none" accept="image/png, image/jpg, image/jpeg">

                          <v-dialog v-model="avatarDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                              <v-card color="black">
                                  <v-toolbar flat dark color="black">
                                      <v-btn icon @click="avatarDialog = false" color="grey">
                                          <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                      <v-spacer></v-spacer>

                                      <v-btn icon dark @click="saveAvatar" :loading="asubmitting" :disabled="asubmitting">
                                          <v-icon>mdi-check</v-icon>
                                      </v-btn>

                                  </v-toolbar>
                                  <v-card-text class="pa-0">
                                      <cropper ref="acropper" :src="avatarFile" :canvas="{
                                                  height: 600,
                                                  width: 800
                                                }" :stencil-props="{
                                                    aspectRatio: 8/6,
                                                    movable: true,
                                                    scalable: true,
                                                }"
                                                image-restriction="stencil"
                                                 />
                                      
                                  </v-card-text>
                              </v-card>
                          </v-dialog>

                      </v-col>

                    </v-row>

                    <div class="pt-2 text-caption text-center">Profile Photo</div>

                    <div class="pt-8 text-subtitle-2 primary--text">More Photos & Videos</div>

                    <v-row justify="start" align="center" class="pt-4">

                        <v-col cols="4" :key="idx" v-for="(pic, idx) in petPics" align="center">
                            <v-img :src="pic" cover style="border-radius:5px" aspect-ratio="1">
                                <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deletePic(idx)">
                                    <v-icon >
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-img>
                            
                        </v-col>

                        <v-col cols="4" :key="'video'+idx" v-for="(vid, idx) in petVideos">
                            <v-img src="/img/icons/player.png" cover style="border-radius:5px" aspect-ratio="1">
                                <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deleteVid(idx)">
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-img>
                        </v-col>

                      <v-col cols="4" :key="-1" class="pa-4" align="center">
                            <v-card flat style="border-radius:5px; border: 2px dashed #adb5bd; width: 90px; height: 90px;" @click="launchPetPhotoPicker">
                                <v-icon class="pa-6" color="primary" size="36">mdi-image-plus</v-icon>
                            </v-card>
                        </v-col>

                        <input type="file" ref="portfoliopic" name="portfoliopic" @change="loadImage($event)" style="display:none" accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime">

                        <v-dialog v-model="portfolioDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                            <v-card color="black">
                                <v-toolbar flat dark color="black">
                                    <v-btn icon @click="portfolioDialog = false" color="grey">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>

                                    <v-btn icon color="white" @click="savePic" :loading="psubmitting" :disabled="psubmitting">
                                        <v-icon>mdi-check</v-icon>
                                    </v-btn>

                                </v-toolbar>
                                <v-card-text class="pa-0">
                                    <cropper ref="cropper" :src="selectedFile" :canvas="{
                                                  height: 600,
                                                  width: 800
                                                }" :stencil-props="{
                                                    aspectRatio: 8/6,
                                                    movable: true,
                                                    scalable: true,
                                                }"
                                                 image-restriction="stencil" />
                                    <!--  <vue-cropper v-show="selectedFile" ref="pcropper" :src="selectedFile" alt="Source Image" :view-mode="1"></vue-cropper> -->
                                </v-card-text>
                            </v-card>
                        </v-dialog>

                    </v-row>

                </div>

            </v-col>
        </v-row>
    </div>

    <v-footer color="white" app padless class="justify-center align-center" style="bottom:0px !important">
        <v-row no-gutters align="center" justify="center">
            <v-col class="text-left pl-4 pb-4">
                <v-btn text @click="prevStep(step)" v-show="step>1" color="grey">
                    Go Back
                </v-btn>
            </v-col>
            <v-col class="text-right pr-4 pb-4 pt-2">
                <v-btn fab color="primary" @click="nextStep(step)" v-show="step < 7">
                    <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn fab color="primary" @click="savePet" v-show="step == 7" :loading="submitting" :disabled="submitting">
                    <v-icon>mdi-check</v-icon>
                </v-btn>

            </v-col>
        </v-row>
    </v-footer>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>


        <v-dialog v-model="age_confirmation_dialog" max-width="400" persistent>
            <v-card>
                <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Are You Sure?</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="age_confirmation_dialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
                <v-card-text>
                    {{ age_confirmation_dialog_text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="age_confirmation_dialog = false;nextStep(4)">Continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    

    <v-overlay :value="dataLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

</div>
</template>

<script>
import {
    Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';
//import gmapsInit from '../../plugins/gmaps';

export default {
    name: 'NewPet',

    async mounted() {
       /*  const google = await gmapsInit();
        this.service = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();
 */


        if (this.$store.getters.petTypes.length == 0) {
            this.getStaticData();
        }
    },

    data: () => ({

        //google : null,

        snackbar: false,
        snackbar_text: '',

        age_confirmation_dialog: false,
        age_confirmation_dialog_show: false,
        age_confirmation_dialog_text: '',

        step: 1,
        purpose: '',
        pet_type: '',
        name: '',
        age_months: '',
        age_years: '',
        gender: '',
        crossbred: false,
        purebred: false,
        breed1: '',
        breed2: '',
        pedigree_certified:0,
        dna_tested: 0,
        size: '',
        weight_number: '',
        weight_unit: 'lbs',
        description: '',
        price: '',
        insta_handle:'',

        vaccinated: '',
        neutered: '',
        mating_first_time: '',
        good_with_kids: '',
        good_with_cats: '',
        good_with_other_dogs: '',
        potty_trained: '',
        price_ccy: '$ USD',
        adoption_fees: 0,
        adoption_fees_ccy: '$ USD',
        stud_fees: 0,
        stud_fees_ccy: '$ USD',

        distance: '100',

        /* location: {
            description: '',
            placeId: '',
            state: '',
            country: '',
            zip:'',
            locality:'',
        },
        searchResults: [],
        service: null,
        geocoder: null,
        sessionToken: null,
        search: null,
        cordinates: null,
        locationPicked: false,

        gettingLocation: false, */
        dataLoading: false,

        petVideos: [],
        petVideosBlobs: [],
        petPics: [],
        petPicsBlobs: [],
        selectedFile: '',
        portfolioDialog: false,
        portfolioFile: null,
        psubmitting: false,

        submitting: false,

        petAvatar: null,
        petAvatarBlob: null,
        avatarDialog: false,
        avatarFile: null,
        asubmitting: false,

        petCertificate: null,
        petCertificateBlob: null,
        certificateDialog: false,
        certificateFile: null,
        csubmitting: false,

        willingness_to_travel: '',
        breeding_terms: '',
        accommodation_responsibility: '',
        preferred_payment_method: '',

        yearRules:  [
                v => !v ? true : /^\d*$/.test(v) || 'Age must be valid',
                v => !v || v <= 20 || 'Age must be valid',
            ],

        monthRules:  [
                v => !v ? true : /^\d*$/.test(v) || 'Age must be valid',
                v => !v || v <= 12 || 'Month must be valid',

            ],

        weightRules:  [
                v => !v ? true : /^\d*$/.test(v) || 'Weight must be valid'
            ],

        priceRules:  [
                v => !v ? true : /^\d*$/.test(v) || 'Price must be valid'
            ],


    }),

    components: {
        Cropper,
    },

    /* metaInfo() {
        return {
            script: [{
                src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCthrQwVxKfF-rjDqqXyAyltUztn4dCwUc&libraries=places`,
                async: true,
                defer: true,
                callback: () => this.MapsInit() // will declare it in methods
            }]
        }
    }, */

    computed: {

        typeBreeds() {
            let petTypes = this.$store.getters.petTypes;
            let selectedPetType = this.pet_type.xid;

            let filteredItem = petTypes.filter(item => {
                return item.xid == selectedPetType
            });

            if (filteredItem.length > 0)
                return filteredItem[0].breeds;
            else
                return [];
        },

    },

    watch: {

        crossbred() {
            if(this.crossbred)
                this.purebred = false;
        },

        purebred() {
            if(this.purebred)
            {
                this.crossbred = false;
                this.breed2 = ''
            }
                
        },

        /* search(val) {
            if (val != '' && val !== null)
                {
                    if((val.length > 3) && (val.length % 2 == 0)) {
                    this.service.getPlacePredictions({
                        input: val,
                        //sessionToken: this.sessionToken,
                        types: ['geocode']
                    }, this.displaySuggestions)
                }
            } 
        } */

    },

    methods: {

        nextStep(x) {

            this.$vuetify.goTo(0);

            window.gtag('event', 'new_pet_step_'+x, {
                event_category: 'New Pet'
            });

            if (x == 1) {
                if (this.pet == '') {
                    this.snackbar_text = "Please select the type of pet";
                    this.snackbar = true;
                } else if (this.purpose == '' || this.purpose == null ) {
                    this.snackbar_text = "Please select your purpose";
                    this.snackbar = true;
                } else {
                    this.step = x + 1;
                }
            }

            if (x == 2) {
                console.log(this.gender)
                if (this.name == '') {
                    this.snackbar_text = "Please enter your pet's name";
                    this.snackbar = true;
                } else if (this.name.includes("@")) {
                    this.snackbar_text = "Please enter a valid name";
                    this.snackbar = true;
                } else if (this.gender == '') {
                    this.snackbar_text = "Please select your pet's gender";
                    this.snackbar = true;
                } else {
                    this.step = x + 1;
                }
            }

            if (x == 3) {
                if (this.breed1 == '' || this.breed1 == null) {
                    this.snackbar_text = "Please select your pet's breed";
                    this.snackbar = true;
                } else {
                    this.step = x + 1;
                }
            }

            if (x == 4) {
                if (this.size == '') {
                    this.snackbar_text = "Please select the size of your pet";
                    this.snackbar = true;
                } else if (this.age_months == '' && this.age_years == '') {
                    this.snackbar_text = "Please enter your pet's age";
                    this.snackbar = true;
                } else if (this.weight_number == '') {
                    this.snackbar_text = "Please enter your pet's weight";
                    this.snackbar = true;
                } else if (this.pet_type.type === 'Dog' && this.purpose === 'lover' && this.age_years == '' && this.age_months <= 11 && this.age_confirmation_dialog_show != true) {
                    this.age_confirmation_dialog_text = "Are you sure you want to list your dog for breeding? It appears too young for breeding.";
                    this.age_confirmation_dialog = true;
                    this.age_confirmation_dialog_show = true;
                } else {
                    this.step = x + 1;
                    this.age_confirmation_dialog_show = false;
                }
            }

            if (x == 5) {
                if (this.price == '' && this.purpose == 'buyer') {
                    this.snackbar_text = "Please provide a price";
                    this.snackbar = true;
                } else if (this.description == '') {
                    this.snackbar_text = "Please write down bit more details of your pet";
                    this.snackbar = true;
                } else {
                    this.step = x + 1;
                }
            }

            if (x == 6) {
                /* if (!this.location || this.location.description == '') {
                    this.snackbar_text = "Please pick your location";
                    this.snackbar = true;
                } else { */
                    this.step = x + 1;
                /* } */
            }

        },
        prevStep(x) {
            this.step = x - 1;
            this.$vuetify.goTo(0);
        },

        filterObject(item, queryText, itemText) {
            return (
                item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 /* ||
                item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 */
            );
        },

        getStaticData() {
            this.dataLoading = true;
            this.$store.dispatch('getStaticData').then(() => {
                    this.dataLoading = false;
                })
                .catch(err => {
                    //console.log(err);
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, there is an error loading details for the page";
                    this.snackbar = true;
                });
        },

        launchPetPhotoPicker() {
            this.$refs.portfoliopic.click();
        },


        loadImage(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                let uploadedFile = input.files[0];
                if(uploadedFile['type'].split('/')[0] === 'video') {
                    let vidsize = (uploadedFile['size'] / (1024*1024)).toFixed(2);
                    if (vidsize > 50) {
                    this.snackbar_text = "Video size must be less than 50MB.";
                    this.snackbar = true;
                    } else {
                        this.saveVideo(uploadedFile);
                    }
                } else {
                    // Check if image size is less than 5 MB
                    let imgSize = (uploadedFile['size'] / (1024*1024)).toFixed(2);
                    if (imgSize > 5) {
                        this.snackbar_text = "Image size must be less than 5MB.";
                        this.snackbar = true;
                    } else {
                        var reader = new FileReader();

                        reader.onload = (e) => {
                            this.selectedFile = e.target.result;
                            this.portfolioDialog = true;
                        };
                        reader.readAsDataURL(input.files[0]);
                    }
                }
            }
        },


        saveVideo(video) {
            this.petVideos.push('');
            this.petVideosBlobs.push(video);
        },
        savePic() {
            this.psubmitting = true;
            const {
                canvas
            } = this.$refs.cropper.getResult();
            if (canvas) {

                this.petPics.push(canvas.toDataURL("image/jpeg"));

                canvas.toBlob((blob) => {
                  this.petPicsBlobs.push(blob);
                  this.psubmitting = false;
                 }, "image/jpeg"); 

                 //console.log(this.petPicsBlobs);

                this.portfolioDialog = false;

                /* const form = new FormData();
                canvas.toBlob((blob) => {

                  this.petPics.push(blob);
                  //form.append("file", blob);
                  // You can use axios, superagent and other libraries instead here
                  /* fetch("http://example.com/upload/", {
                    method: "POST",
                    body: form,
                  }); */
                // Perhaps you should add the checking file format
                //}, "image/jpeg"); 
            }
        },

        deletePic(idx)
        {
          this.petPics.splice(idx, 1);
          this.petPicsBlobs.splice(idx, 1);
        },
        deleteVid(idx)
        {
            this.petVideos.splice(idx, 1);
            this.petVideosBlobs.splice(idx, 1);
        },



        launchAvatarPicker() {
            this.$refs.avatarpic.click();
        },

        loadAvatar(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.avatarFile = e.target.result;
                    this.avatarDialog = true;

                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        saveAvatar() {
          this.asubmitting = true;
            const {
                canvas
            } = this.$refs.acropper.getResult();
            if (canvas) {

                this.petAvatar = canvas.toDataURL("image/jpeg");

                canvas.toBlob((blob) => {
                  this.petAvatarBlob = blob;
                  this.asubmitting = false;
                 }, "image/jpeg"); 


                this.avatarDialog = false;

            }
        },

        deleteAvatar()
        {
          this.petAvatar = null;
          this.petAvatarBlob = null;
        },



        launchCertificatePicker() {
            this.$refs.certificatepic.click();
        },

        loadCertificate(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.certificateFile = e.target.result;
                    this.certificateDialog = true;

                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        saveCertificate() {
          this.csubmitting = true;
            const {
                canvas
            } = this.$refs.ccropper.getResult();
            if (canvas) {

                this.petCertificate = canvas.toDataURL("image/jpeg");

                canvas.toBlob((blob) => {
                  this.petCertificateBlob = blob;
                  this.csubmitting = false;
                 }, "image/jpeg"); 


                this.certificateDialog = false;

            }
        },

        deleteCertificate()
        {
          this.petCertificate = null;
          this.petCertificateBlob = null;
        },


        /* clearLocation() {

            //console.log('clear location');
            this.location = {
                description: '',
                placeId: '',
                state: '',
                country: '',
                zip:'',
                locality:'',
            };
            this.searchResults = [];
            this.locationPicked = false;

            
        },


        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = []
                return
            }
            this.searchResults = predictions.map(prediction => ({
                description: prediction.description,
                placeId: prediction.place_id
            }))
        },

        getPlaceDetails() {
            if (this.location && this.location.placeId != '') {
                //console.log(this.location.placeId);
                this.geocoder.geocode({
                    placeId: this.location.placeId
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.cordinates = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        };

                        this.locationPicked = true;
                        //console.log(this.cordinates);
                        //this.closeFieldDialog();

                    }
                });
            }
        },

        async getCurrentLocation() {
            this.gettingLocation = true;
            try {

                let location = await this.getLocation();

                //console.log(location);
                this.cordinates = {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude
                };

                this.geocoder.geocode({
                    location: this.cordinates
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        //console.log(results[0]);
                        this.gettingLocation = false;

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.location.description = results[0].formatted_address;
                        this.location.placeId = results[0].place_id;

                        this.searchResults = [{
                            description: results[0].formatted_address,
                            placeId: results[0].place_id
                        }];

                        this.locationPicked = true;

                        //console.log(this.searchResults);

                        //this.closeFieldDialog();

                    }
                });

            } catch (e) {
                this.gettingLocation = false;
                this.errorStr = e.message;
            }

        },

        async getLocation() {

            return new Promise((resolve, reject) => {

                if (!("geolocation" in navigator)) {
                    reject(new Error('Geolocation is not available.'));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                    resolve(pos);
                }, err => {
                    reject(err);
                });

            });
        }, */

        savePet()
        {

          this.submitting = true;

                let formData = new FormData();

                formData.append('pet_type', this.pet_type.xid);
                formData.append('purpose', this.purpose);
                formData.append('crossbred', this.crossbred);
                formData.append('purebred', this.purebred);
                formData.append('breed1', JSON.stringify(this.breed1));
                formData.append('breed2', JSON.stringify(this.breed2));
                formData.append('pedigree_certified', this.pedigree_certified);
                formData.append('dna_tested', this.dna_tested);
                formData.append('gender', this.gender);
                formData.append('name', this.name);
                formData.append('size', this.size);
                formData.append('age_months', this.age_months);
                formData.append('age_years', this.age_years);
                formData.append('weight_number', this.weight_number);
                formData.append('weight_unit', this.weight_unit);
                formData.append('description', this.description);
                formData.append('price', this.price);
                formData.append('insta_handle', this.insta_handle);
                /* formData.append('location', JSON.stringify(this.location));
                formData.append('cordinates', JSON.stringify(this.cordinates)); */

                formData.append('vaccinated', this.vaccinated);
                formData.append('neutered', this.neutered);
                formData.append('mating_first_time', this.mating_first_time);
                formData.append('good_with_kids', this.good_with_kids);
                formData.append('good_with_cats', this.good_with_cats);
                formData.append('good_with_other_dogs', this.good_with_other_dogs);
                formData.append('potty_trained', this.potty_trained);
                formData.append('price_ccy', this.price_ccy);
                formData.append('adoption_fees', this.adoption_fees);
                formData.append('adoption_fees_ccy', this.adoption_fees_ccy);
                formData.append('stud_fees', this.stud_fees);
                formData.append('stud_fees_ccy', this.stud_fees_ccy);

                formData.append('willingness_to_travel', this.willingness_to_travel);
                formData.append('breeding_terms', this.breeding_terms);
                formData.append('accommodation_responsibility', this.accommodation_responsibility);
                formData.append('preferred_payment_method', this.preferred_payment_method);


                if (this.petPicsBlobs) {
                        for (var i = 0; i < this.petPicsBlobs.length; i++) {
                            let file = this.petPicsBlobs[i];
                            formData.append('photos[' + i + ']', file);
                        }
                    }

                if (this.petVideosBlobs) {
                    for (let v = 0; v < this.petVideosBlobs.length; v++) {
                        let file = this.petVideosBlobs[v];
                        formData.append('videos[' + v + ']', file);
                    }
                }

                if (this.petAvatarBlob) {
                    formData.append('avatar', this.petAvatarBlob);
                }

                if (this.petCertificateBlob) {
                    formData.append('certificate', this.petCertificateBlob);
                }


                this.$store.dispatch('newPet', formData)
                    .then((resp) => {

                        window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});
                        window.gtag('event', 'conversion', {'send_to': 'AW-10848052375/hpP0COKMoaYDEJfJ4LQo'});
                        window.gtag('event', 'new_pet_added', {
                            event_category: 'New Pet'
                        });

                        window.fbq('trackCustom', 'NewPet', {purpose: this.purpose});

                        window.twq('event', 'tw-ocimw-ocirx', {});

                        this.submitting = false;
                        this.$router.push('/home');

                    })
                    .catch(err => {
                        //console.log(err);
                        //console.log(err.response.data.error['userEmail'][0])

                        this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

        },


    }
}
</script>
