<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">

                <div>
                    <div class="text-h6 font-weight-regular py-4 grey--text">
                        Tell us a bit about yourself...
                    </div>


                    <v-row justify="center" no-gutters>
                        <v-col cols="12" class="justify-center">

                            <v-row no-gutters>
                                <v-col class="px-2">
                                    <v-progress-linear :color="step > 0 ? 'primary' : '#ccc'"
                                        :value="(step / maxSteps) * 100" rounded height="8"></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <div v-show="step == 1" class="mb-4">

                        <v-row class="my-2 ">

                            <v-col cols="12">
                                <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Organization
                                    Information</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Organization Name:</div>
                                <v-text-field v-model="organization_name" dense outlined hide-details flat type="text"
                                    placeholder="Organization Name"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Official Phone Number:</div>
                                <v-text-field v-model="phone_number" dense outlined hide-details flat type="text"
                                    placeholder="Enter Official Phone Number"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Official Email Address:</div>
                                <v-text-field v-model="email" dense outlined hide-details flat type="email"
                                    placeholder="Enter Official Email Address"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Website:</div>
                                <v-text-field v-model="website" dense outlined hide-details flat type="text"
                                    placeholder="http://www.example.com"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Social Media Links:</div>
                                <v-textarea v-model="social_media_links" dense outlined hide-details flat
                                    :placeholder="social_media_placeholder"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Type of Organization:</div>
                                <v-select dense outlined flat solo hide-details v-model="organization_type"
                                    :items="['Shelter', 'Rescue', 'Foster-Based', 'Other']"
                                    label="Select Organization Type" class="mt-1"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Legal Status:</div>
                                <v-select dense outlined flat solo hide-details v-model="legal_status"
                                    :items="['Non-profit', 'Registered Charity', 'Other']" label="Select Legal Status"
                                    class="mt-1"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Mission Statement:</div>
                                <v-textarea v-model="mission_statement" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Years of Operation:</div>
                                <v-text-field v-model="years_of_operation" dense outlined hide-details flat
                                    type="number" placeholder="0"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="text-left"><v-btn depressed :to="'/home'" >
                                    Cancel
                                </v-btn></v-col>
                            <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                                    Continue
                                </v-btn></v-col>
                        </v-row>

                    </div>

                    <div v-show="step == 2" class="mb-4">

                        <v-row class="mt-2">


                            <v-col cols="12">
                                <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Animal Intake and Care
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Sources of Animals:</div>
                                <v-select dense outlined flat solo hide-details v-model="animal_sources"
                                    :items="['Stray intake', 'Owner surrenders', 'Transfers from other organizations']"
                                    label="Select Sources of Animals" class="mt-1"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Describe how you evaluate and
                                    intake animals:</div>
                                <v-textarea v-model="intake_process" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Type of housing:</div>
                                <v-text-field v-model="housing_type" dense outlined hide-details flat type="te"
                                    placeholder="kennels, foster homes, etc."></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Cleaning and sanitation
                                    protocols:</div>
                                <v-textarea v-model="cleaning_protocols" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Enrichment activities for
                                    animals:</div>
                                <v-textarea v-model="enrichment_activities" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Veterinary care provided:
                                </div>
                                <v-textarea v-model="veterinary_care" dense outlined hide-details flat
                                    placeholder="vaccinations, spay/neuter, etc."></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">How do you assess the
                                    temperament and behavior of animals?</div>
                                <v-textarea v-model="behavioral_assessment" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Do you accept and care for
                                    animals with special needs?</div>
                                <v-radio-group v-model="accepts_special_needs" class="mt-0" row hide-details>
                                    <v-radio label="Yes" :value="1"></v-radio>
                                    <v-radio label="No" :value="0"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                                    Back
                                </v-btn></v-col>
                            <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                                    Continue
                                </v-btn></v-col>
                        </v-row>

                    </div>

                    <div v-show="step == 3" class="mb-4">

                        <v-row class="mt-2">


                            <v-col cols="12">
                                <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Adoption/Rehoming
                                    Process</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Describe your adoption
                                    application process.</div>
                                <v-textarea v-model="adoption_application_process" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">What criteria do you use to
                                    evaluate potential adopters?</div>
                                <v-textarea v-model="screening_criteria" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Do you conduct home visits
                                    prior to adoption?</div>
                                <v-radio-group v-model="conducts_home_visits" class="mt-0" row hide-details>
                                    <v-radio label="Yes" :value="1"></v-radio>
                                    <v-radio label="No" :value="0"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">What kind of support do you
                                    offer to adopters after adoption?</div>
                                <v-textarea v-model="post_adoption_support" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                                    Back
                                </v-btn></v-col>
                            <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                                    Continue
                                </v-btn></v-col>
                        </v-row>

                    </div>

                    <div v-show="step == 4" class="mb-4">

                        <v-row class="mt-2">


                            <v-col cols="12">
                                <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Policies and
                                    Procedures</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Euthanasia Policy:</div>
                                <v-textarea v-model="euthanasia_policy" dense outlined hide-details flat
                                    placeholder="Under what circumstances do you consider euthanasia?"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Spay/Neuter Policy: (Do you
                                    require adopters to spay/neuter their pets?)</div>
                                <v-radio-group v-model="requires_spay_neuter" class="mt-0" row hide-details>
                                    <v-radio label="Yes" :value="1"></v-radio>
                                    <v-radio label="No" :value="0"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Return Policy:</div>
                                <v-textarea v-model="return_policy" dense outlined hide-details flat
                                    placeholder="What is your policy if an adopter can no longer care for the animal?"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Do you have a volunteer
                                    program?</div>
                                <v-radio-group v-model="has_volunteer_program" class="mt-0" row hide-details>
                                    <v-radio label="Yes" :value="1"></v-radio>
                                    <v-radio label="No" :value="0"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" v-show="has_volunteer_program == 1">
                                <div class="primary--text text-subtitle-2 font-weight-600">If so, how can people get
                                    involved in volunteer program?</div>
                                <v-textarea v-model="volunteer_info" dense outlined hide-details flat
                                    placeholder="Do you have a volunteer program? If so, how can people get involved?"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Do you have a foster program?
                                </div>
                                <v-radio-group v-model="has_foster_program" class="mt-0" row hide-details>
                                    <v-radio label="Yes" :value="1"></v-radio>
                                    <v-radio label="No" :value="0"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" v-show="has_foster_program == 1">
                                <div class="primary--text text-subtitle-2 font-weight-600">What are the requirements for
                                    becoming a foster parent?</div>
                                <v-textarea v-model="foster_requirements" dense outlined hide-details flat
                                    placeholder="What are the requirements for becoming a foster parent?"></v-textarea>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                                    Back
                                </v-btn></v-col>
                            <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                                    Continue
                                </v-btn></v-col>
                        </v-row>

                    </div>

                    <div v-show="step == 5" class="mb-4">

                        <v-row class="mt-2">


                            <v-col cols="12">
                                <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Partnerships and
                                    Community Engagement</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Do you collaborate with other
                                    animal welfare organizations?</div>
                                <v-textarea v-model="partner_organizations" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">How do you engage with the
                                    community to promote adoption and responsible pet ownership?</div>
                                <v-textarea v-model="community_outreach" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">How do you fund your
                                    operations?</div>
                                <v-textarea v-model="fundraising_efforts" dense outlined hide-details flat
                                    placeholder=""></v-textarea>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                                    Back
                                </v-btn></v-col>
                            <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                                    Continue
                                </v-btn></v-col>
                        </v-row>
                    </div>

                    <div v-show="step == 6" class="mb-4">

                        <v-row class="mt-2">


                            <v-col cols="12">
                                <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Additional Information
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Number of animals currently
                                    in your care:</div>
                                <v-text-field v-model="animals_in_care" dense outlined hide-details flat type="number"
                                    placeholder="0"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Types of animals you
                                    typically have available for adoption:</div>
                                <v-textarea v-model="available_animal_types" class="mt-1" dense outlined hide-details
                                    auto-grow placeholder=""></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <div class="primary--text text-subtitle-2 font-weight-600">Is there any additional
                                    information you would like to share about your organization?</div>
                                <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder=""
                                    v-model="additional_information"></v-textarea>
                            </v-col>

                            <v-col cols="12">

                                <div>
                                    <p class="text-caption">By submitting, you acknowledge that the information provided
                                        is accurate and complete to the best of your knowledge. You understand that
                                        Petmeetly and/or the pet provider may conduct additional checks or interviews as part of
                                        the vetting process.</p>
                                </div>
                            </v-col>


                       

                        </v-row>

                        <v-row>
                            <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                                    Back
                                </v-btn></v-col>
                            <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)" :loading="submitting" :disabled="submitting">
                                        Submit
                                    </v-btn></v-col>
                        </v-row>
                    </div>

                </div>


                <v-snackbar v-model="snackbar">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>

                <v-overlay :value="dataLoading">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>

            </v-col>
        </v-row>
    </v-container>


</template>

<script>
export default {
    name: 'ShelterQuestionnaire',


    mounted() {


        if (this.$store.getters.userDetails.category !== 'shelter') {
            this.$router.replace('home');
        }
        else {
            this.getShelterQuestionnaire();
        }

    },

    data() {
        return {
            submitting: false,
            dataLoading: false,
            snackbar: false,
            snackbar_text: '',
            social_media_placeholder: `facebook:
instagram:
twitter:`,
            organization_name: '',
            phone_number: '',
            email: '',
            website: '',
            social_media_links: '',
            organization_type: '',
            legal_status: '',
            mission_statement: '',
            years_of_operation: '',
            animal_sources: '',
            intake_process: '',
            housing_type: '',
            cleaning_protocols: '',
            enrichment_activities: '',
            veterinary_care: '',
            behavioral_assessment: '',
            accepts_special_needs: '',
            adoption_application_process: '',
            screening_criteria: '',
            conducts_home_visits: '',
            post_adoption_support: '',
            euthanasia_policy: '',
            requires_spay_neuter: '',
            return_policy: '',
            has_volunteer_program: '',
            volunteer_info: '',
            has_foster_program: '',
            foster_requirements: '',
            partner_organizations: '',
            community_outreach: '',
            fundraising_efforts: '',
            veterinary_reference: '',
            organization_references: '',
            animals_in_care: '',
            available_animal_types: '',
            additional_information: '',
            step: 1,
            maxSteps: 6,
        }
    },

    methods: {

        nextStep(x) {

        this.$vuetify.goTo(0);


        if (x == 1) {
            if (this.organization_name.trim() === ''
                || this.phone_number.trim() === ''
                || this.email.trim() === ''
                || this.website.trim() === ''
                || this.social_media_links.trim() === ''
                || this.organization_type === ''
                || this.legal_status === ''
                || this.years_of_operation === ''
                ) {
                this.snackbar_text = "Please provide all details";
                this.snackbar = true;
                }
                else
            { 
                this.submit();
                this.step = x + 1;
             } 
        }

        if (x == 2) {
            if (this.animal_sources === ''
                || this.intake_process.trim() === ''
                || this.housing_type === ''
                || this.cleaning_protocols.trim() === ''
                || this.enrichment_activities.trim() === ''
                || this.veterinary_care.trim() === ''
                || this.behavioral_assessment.trim() === ''
                || this.accepts_special_needs === ''
                ) {
                this.snackbar_text = "Please provide all details";
                this.snackbar = true;
                }
                else
            { 
                this.submit();
                this.step = x + 1;
             } 
        }

        if (x == 3) {
            if (this.adoption_application_process === ''
                || this.screening_criteria.trim() === ''
                || this.conducts_home_visits === ''
                || this.post_adoption_support.trim() === ''
                ) {
                this.snackbar_text = "Please provide all details";
                this.snackbar = true;
                }
                else
            { 
                this.submit();
                this.step = x + 1;
             } 
        }

        if (x == 4) {
            if (this.euthanasia_policy === ''
                || this.requires_spay_neuter === ''
                || this.return_policy.trim() === ''
                || this.has_volunteer_program === ''
                || (this.has_volunteer_program == 1 && this.volunteer_info.trim() === '')
                || this.has_foster_program === ''
                || (this.has_foster_program == 1 && this.foster_requirements.trim() === '')
                ) {
                this.snackbar_text = "Please provide all details";
                this.snackbar = true;
                }
                else
            { 
                this.submit();
                this.step = x + 1;
             } 
        }

        if (x == 5) {
            if (this.partner_organizations.trim() === ''
                || this.community_outreach.trim() === ''
                || this.fundraising_efforts.trim() === ''
                ) {
                this.snackbar_text = "Please provide all details";
                this.snackbar = true;
                }
                else
            { 
                this.submit();
                this.step = x + 1;
             } 
        }

        if (x == 6) {
            if (this.animals_in_care === ''
                || this.available_animal_types.trim() === ''
                ) {
                this.snackbar_text = "Please provide all details";
                this.snackbar = true;
                }
                else
            { 
                this.step = x + 1;
                this.submit();
                
             } 
        }

        },
        prevStep(x) {
            this.step = x - 1;
            this.$vuetify.goTo(0);
        },


        getShelterQuestionnaire() {
            this.dataLoading = true;
            this.$store.dispatch('getShelterQuestionnaire').then((resp) => {

                this.dataLoading = false;

                this.organization_name = resp.data.organization_name == null ? '' : resp.data.organization_name;
                this.phone_number = resp.data.phone_number == null ? '' : resp.data.phone_number;
                this.email = resp.data.email == null ? '' : resp.data.email;
                this.website = resp.data.website == null ? '' : resp.data.website;
                this.social_media_links = resp.data.social_media_links == null ? '' : resp.data.social_media_links;
                this.organization_type = resp.data.organization_type == null ? '' : resp.data.organization_type;
                this.legal_status = resp.data.legal_status == null ? '' : resp.data.legal_status;
                this.mission_statement = resp.data.mission_statement == null ? '' : resp.data.mission_statement;
                this.years_of_operation = resp.data.years_of_operation == null ? '' : resp.data.years_of_operation;
                this.animal_sources = resp.data.animal_sources == null ? '' : resp.data.animal_sources;
                this.intake_process = resp.data.intake_process == null ? '' : resp.data.intake_process;
                this.housing_type = resp.data.housing_type == null ? '' : resp.data.housing_type;
                this.cleaning_protocols = resp.data.cleaning_protocols == null ? '' : resp.data.cleaning_protocols;
                this.enrichment_activities = resp.data.enrichment_activities == null ? '' : resp.data.enrichment_activities;
                this.veterinary_care = resp.data.veterinary_care == null ? '' : resp.data.veterinary_care;
                this.behavioral_assessment = resp.data.behavioral_assessment == null ? '' : resp.data.behavioral_assessment;
                this.accepts_special_needs = resp.data.accepts_special_needs == null ? '' : resp.data.accepts_special_needs;
                this.adoption_application_process = resp.data.adoption_application_process == null ? '' : resp.data.adoption_application_process;
                this.screening_criteria = resp.data.screening_criteria == null ? '' : resp.data.screening_criteria;
                this.conducts_home_visits = resp.data.conducts_home_visits == null ? '' : resp.data.conducts_home_visits;
                this.post_adoption_support = resp.data.post_adoption_support == null ? '' : resp.data.post_adoption_support;
                this.euthanasia_policy = resp.data.euthanasia_policy == null ? '' : resp.data.euthanasia_policy;
                this.requires_spay_neuter = resp.data.requires_spay_neuter == null ? '' : resp.data.requires_spay_neuter;
                this.return_policy = resp.data.return_policy == null ? '' : resp.data.return_policy;
                this.has_volunteer_program = resp.data.has_volunteer_program == null ? '' : resp.data.has_volunteer_program;
                this.volunteer_info = resp.data.volunteer_info == null ? '' : resp.data.volunteer_info;
                this.has_foster_program = resp.data.has_foster_program == null ? '' : resp.data.has_foster_program;
                this.foster_requirements = resp.data.foster_requirements == null ? '' : resp.data.foster_requirements;
                this.partner_organizations = resp.data.partner_organizations == null ? '' : resp.data.partner_organizations;
                this.community_outreach = resp.data.community_outreach == null ? '' : resp.data.community_outreach;
                this.fundraising_efforts = resp.data.fundraising_efforts == null ? '' : resp.data.fundraising_efforts;
                this.veterinary_reference = resp.data.veterinary_reference == null ? '' : resp.data.veterinary_reference;
                this.organization_references = resp.data.organization_references == null ? '' : resp.data.organization_references;
                this.animals_in_care = resp.data.animals_in_care == null ? '' : resp.data.animals_in_care;
                this.available_animal_types = resp.data.available_animal_types == null ? '' : resp.data.available_animal_types;
                this.additional_information = resp.data.additional_information == null ? '' : resp.data.additional_information;
            })
                .catch(err => {
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, Unable to get the details";
                    this.snackbar = true;
                });

        },



        submit() {

           
                this.submitting = true;

                let formData = new FormData();
                formData.append('organization_name', this.organization_name);
                formData.append('phone_number', this.phone_number);
                formData.append('email', this.email);
                formData.append('website', this.website);
                formData.append('social_media_links', this.social_media_links);
                formData.append('organization_type', this.organization_type);
                formData.append('legal_status', this.legal_status);
                formData.append('mission_statement', this.mission_statement);
                formData.append('years_of_operation', this.years_of_operation);
                formData.append('animal_sources', this.animal_sources);
                formData.append('intake_process', this.intake_process);
                formData.append('housing_type', this.housing_type);
                formData.append('cleaning_protocols', this.cleaning_protocols);
                formData.append('enrichment_activities', this.enrichment_activities);
                formData.append('veterinary_care', this.veterinary_care);
                formData.append('behavioral_assessment', this.behavioral_assessment);
                formData.append('accepts_special_needs', this.accepts_special_needs);
                formData.append('adoption_application_process', this.adoption_application_process);
                formData.append('screening_criteria', this.screening_criteria);
                formData.append('conducts_home_visits', this.conducts_home_visits);
                formData.append('post_adoption_support', this.post_adoption_support);
                formData.append('euthanasia_policy', this.euthanasia_policy);
                formData.append('requires_spay_neuter', this.requires_spay_neuter);
                formData.append('return_policy', this.return_policy);
                formData.append('has_volunteer_program', this.has_volunteer_program);
                formData.append('volunteer_info', this.volunteer_info);
                formData.append('has_foster_program', this.has_foster_program);
                formData.append('foster_requirements', this.foster_requirements);
                formData.append('partner_organizations', this.partner_organizations);
                formData.append('community_outreach', this.community_outreach);
                formData.append('fundraising_efforts', this.fundraising_efforts);
                formData.append('veterinary_reference', this.veterinary_reference);
                formData.append('organization_references', this.organization_references);
                formData.append('animals_in_care', this.animals_in_care);
                formData.append('available_animal_types', this.available_animal_types);
                formData.append('additional_information', this.additional_information);
                formData.append('step', this.step);

                this.$store.dispatch('submitShelterQuestionnaire', {
                    formData
                })
                    .then((resp) => {
                        this.submitting = false;
                        if(this.step == 7)
                        {
                            this.$router.replace('home');
                        }
                        

                    })
                    .catch(err => {
                        this.submitting = false;
                        this.snackbar_text = "Oops, there is an error while saving. Please retry or contact support";
                        this.snackbar = true;
                    });
            
        }
    }

}

</script>
