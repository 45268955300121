<template>
<v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable max-width="450" transition="dialog-transition" :retain-focus="false">

    <v-card class="petpop">

        <!-- Sticky Header (Initially Hidden) -->
        <div v-if="isScrolled" class="sticky-header" style="position: sticky; top: 0; background: #7E0DE7; z-index: 10; padding: 10px; height: 72px; display: flex; align-items: center;">
  
        <!-- Left Button -->
        <v-btn @click="handleChange" dark @click.stop="close" icon v-if="$vuetify.breakpoint.xsOnly">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        
        <!-- Spacer to center the title when the right button is not present -->
        <div style="flex-grow: 1;"></div>

        <!-- Pet Name and Icons -->
        <div class="text-subtitle-1 font-weight-medium" style="flex-grow: 2; text-align: center;">
            {{pet.name}} 
            <v-icon v-show="pet.is_premium" size="20" color="#d4af37" class="px-1">mdi-crown</v-icon> 
            <v-icon v-show="pet.video_verified" size="20" color="success">mdi-check-decagram</v-icon>
        </div>
        
        <!-- Spacer to center the title when the right button is not present -->
        <div style="flex-grow: 1;"></div>

        <!-- Right Button -->
        <v-btn icon @click="handleChange" dark @click.stop="close" v-if="!$vuetify.breakpoint.xsOnly">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </div>

        <div ref="scrollableContent" @scroll="handleScroll" style="overflow-y: auto; height: 100vh;">

        <v-btn @click="handleChange" dark @click.stop="close" fab top left absolute depressed class="mt-11" style="background-color:rgba(0,0,0,0.40);" v-if="$vuetify.breakpoint.xsOnly && !isScrolled">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn @click="handleChange" dark @click.stop="close" fab top right absolute depressed class="mt-11" style="background-color:rgba(0,0,0,0.40);" v-if="!$vuetify.breakpoint.xsOnly && !isScrolled">
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-row no-gutters ref="carouselSection">
            <v-col cols="12" class="justify-center">

                <v-carousel hide-delimiters height="300" :show-arrows="showArrows" v-if="showCarousal" @change="handleChange">
                    <v-carousel-item v-for="(item,i) in pet.photos" :key="i" :src="item.photo" cover class="black"></v-carousel-item>
                    <v-carousel-item v-for="(item,i) in pet.videos" :key="'player'+i" ><video-player :options="videoOptions" :source="getVideoUrl(item)" :id="item.xid"/></v-carousel-item>
                </v-carousel>

                <v-avatar v-else tile size="300" color="primary" style="align:center; width:100%" class="rounded-lg">
                    <v-icon size="80" class="white--text">{{pet.pet_type ? '$vuetify.icons.'+pet.pet_type.icon : 'mdi-paw'}}</v-icon>
                </v-avatar>

            </v-col>
        </v-row>

        



        <div style="border-bottom: 1px solid #eee">
        <div>
            <v-list-item three-line>
          

          <v-list-item-content>
            <v-list-item-title class="text-h6 font-weight-bold">{{pet.name}} 
                <v-icon v-show="pet.is_premium" size="20" color="#d4af37" class="px-1">mdi-crown</v-icon> 
                <v-icon v-show="pet.video_verified" size="20" color="success">mdi-check-decagram</v-icon>
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption font-weight-medium">{{pet.breed}}</v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption font-weight-medium">{{pet.age ? pet.age.long : '-'}} old, {{pet.gender == 'M' ? 'Male' : 'Female'}}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="markFavourite" :loading="favLoading" v-show="pet.user.xid != $store.getters.userDetails.xid">
                    <v-icon color="red" v-if="pet.is_favorite">
                        mdi-heart
                    </v-icon>
                    <v-icon v-else color="grey lighten-1">
                        mdi-heart-outline
                    </v-icon>
                </v-btn>
                    <v-list-item-action-text>&nbsp;</v-list-item-action-text>
                <v-list-item-action-text class="text-caption" v-show="pet.views > 5 && pet.user.xid == $store.getters.userDetails.xid">{{pet.views}} views</v-list-item-action-text>
            </v-list-item-action>
            

        </v-list-item>
        </div>

        <div>
            <div v-show="pet.price > 0" class="px-4 pb-2"><span class="text-caption grey--text text--darken-1 font-weight-medium">Price: </span><span class="text-body-2 font-weight-bold light-blue--text">{{Number(pet.price).toLocaleString()}} {{ pet.price_ccy == null ? '$ USD' : pet.price_ccy }}</span></div>
            <div v-show="pet.adoption_fees > 0" class="px-4 pb-2"><span class="text-caption grey--text text--darken-1 font-weight-medium">Adoption Fee: </span><span class="text-body-2 font-weight-bold light-blue--text">{{Number(pet.adoption_fees).toLocaleString()}} {{ pet.adoption_fees_ccy == null ? '$ USD' : pet.adoption_fees_ccy }}</span></div>
            <div v-show="pet.stud_fees > 0" class="px-4 pb-2"><span class="text-caption grey--text text--darken-1 font-weight-medium">Stud Fee: </span><span class="text-body-2 font-weight-bold light-blue--text">{{Number(pet.stud_fees).toLocaleString()}} {{ pet.stud_fees_ccy == null ? '$ USD' : pet.stud_fees_ccy }}</span></div>
        
        </div>
    </div>

                               

        
        <v-card-text class="pa-4  black--text">

        
 <v-sheet outlined rounded class="pa-1 py-2 ma-n1" color="#f8f8f8" >



                <v-chip label  small  color="#f8f8f8">
                    <v-icon left small color="primary">mdi-weight</v-icon>
                {{pet.weight}} {{pet.weight_unit}}</v-chip>

                <v-chip label v-if="pet.purebred" small  color="#f8f8f8">
                    <v-icon left small color="primary">mdi-water</v-icon>
                Purebred</v-chip>

                <v-chip label v-if="pet.pedigree_certified && pet.pedigree_verified" small  color="#f8f8f8">
                    <v-icon left small color="primary">mdi-seal-variant</v-icon>
                Pedigree Certified</v-chip>

                <v-chip label v-if="pet.dna_tested && pet.dna_verified" small color="#f8f8f8">
                    <v-icon left small color="primary">mdi-dna</v-icon>
                DNA Tested</v-chip>

                <v-chip label v-if="pet.vaccinated" small color="#f8f8f8">
                    <v-icon left small color="primary">mdi-needle</v-icon>
                Vaccinated</v-chip>

                <v-chip label v-if="pet.potty_trained" small color="#f8f8f8">
                    <v-icon left small color="primary">mdi-toilet</v-icon>
                Potty Trained</v-chip>

                <v-chip label v-if="pet.is_microchipped" small color="#f8f8f8">
                    <v-icon left small color="primary">mdi-chip</v-icon>
                Microchippped</v-chip>

                <v-chip label v-if="pet.neutered" small color="#f8f8f8">
                    <v-icon left small color="primary">mdi-check</v-icon>
                Neutered</v-chip>

                <v-chip label v-if="pet.is_house_trained" small color="#f8f8f8">
                    <v-icon left small color="success">mdi-check</v-icon>
                House Trained</v-chip>

                <v-chip label v-if="pet.good_with_kids" small color="#f8f8f8">
                    <v-icon left small color="success">mdi-check</v-icon>
                Good with kids</v-chip>

                <v-chip label v-if="pet.good_with_kids == 0" small color="#f8f8f8">
                    <v-icon left small color="error">mdi-close</v-icon>
                Not good with kids</v-chip>

                <v-chip label v-if="pet.good_with_cats" small color="#f8f8f8">
                    <v-icon left small color="success">mdi-check</v-icon>
                Good with cats</v-chip>

                <v-chip label v-if="pet.good_with_cats == 0" small color="#f8f8f8">
                    <v-icon left small color="error">mdi-close</v-icon>
                Not good with cats</v-chip>

                <v-chip label v-if="pet.good_with_other_dogs" small color="#f8f8f8">
                    <v-icon left small color="success">mdi-check</v-icon>
                Good with dogs</v-chip>

                <v-chip label v-if="pet.good_with_other_dogs == 0" small color="#f8f8f8">
                    <v-icon left small color="error">mdi-close</v-icon>
                Not good with dogs</v-chip>

                



    </v-sheet>

            <v-row justify="center" no-gutters>
                <v-col cols="12" class="justify-center text-pre-wrap py-4 text-caption" >

                
                    {{pet.description}}
                
                    <v-divider class="my-4" style="border-color: #eee" v-if="pet.willingness_to_travel != null || pet.breeding_terms != null || pet.accommodation_responsibility != null || pet.preferred_payment_method != null"></v-divider>

                    
                    <div class="" v-if="pet.willingness_to_travel != null"><strong>Willingness to travel: </strong>
                        <br/><div class="pb-2">{{pet.willingness_to_travel}}</div></div>
                    <div class="" v-if="pet.breeding_terms != null"><strong>Breeding Terms:  </strong>
                        <br/><div class="pb-2">{{pet.breeding_terms}}</div></div>
                    <div class="" v-if="pet.accommodation_responsibility != null"><strong>Accommodation Responsibility:  </strong>
                        <br/><div class="pb-2">{{pet.accommodation_responsibility}}</div></div>
                    <div class="" v-if="pet.preferred_payment_method != null"><strong>Preferred payment method:  </strong>
                        <br/><div class="pb-2">{{pet.preferred_payment_method}}</div></div>
                   
                </v-col>
            </v-row>

            <div v-if="pet.purpose == 'lover' && (pet.extra_info_status == 'submitted' || pet.extra_info_status == 'verified')">
                
                <v-sheet  class="pa-4 my-4 mt-2 rounded-lg" style="background-color: #f2f2ff;">
                    
                <div class="text-caption">Has the pet been bred before?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.previous_breeding == 1 ? "Yes" : "No" }}. {{ pet.breeding_count > 0 ? (pet.breeding_count > 1 ? pet.breeding_count + ' times' : 'One time') : '' }}</div>

                <div class="text-caption pt-2" v-if="pet.extra_info_verified">Does the pet have any known health issues?</div>
                <div class="text-caption black--text font-weight-bold" v-if="pet.extra_info_verified">{{ pet.health_issues }}</div>

                
                <div class="float-right mt-n4 primary--text text-caption"  style="cursor:pointer" v-if="!moreInfo" @click="moreInfo = !moreInfo">View More...</div>
                
                <v-expand-transition v-if="moreInfo">
                    <div>

                <div class="text-caption pt-2" v-if="pet.extra_info_verified">Pet's personality, energy level, and behavior with people and other animals.</div>
                <div class="text-caption black--text font-weight-bold" v-if="pet.extra_info_verified">{{ pet.temperament }}</div>

                <div class="text-caption pt-2">Familiar with the breed standard and any genetic health issues associated with this breed?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.familiar_with_breed_standard == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">Consulted with a veterinarian about breeding the pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.consulted_veterinarian == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">Has the pet been tested for any breed-specific health conditions or genetic disorders?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.has_health_tests == 1 ? 'Yes' : 'No'}}. {{ pet.extra_info_verified ? pet.health_test_results : '' }}</div>

                <div class="text-caption pt-2">Willing to sign a breeding contract?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.willing_to_sign_contract == 1 ? 'Yes' : 'No' }}</div>

            </div>
            </v-expand-transition>    
            
            </v-sheet>
            </div>


            <div v-if="pet.extra_info_verified && (pet.purpose == 'adopter' || pet.purpose == 'buyer')">
                
                <v-sheet  class="pa-4 my-4 mt-2 rounded-lg" style="background-color: #f2f2ff;">
                    
                <div class="text-caption">Is the pet spayed/neutered?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.is_pet_spayed_neutered == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption" v-if="!pet.is_pet_spayed_neutered">Willing to have the pet spayed/neutered before sale?</div>
                <div class="text-caption black--text font-weight-bold" v-if="!pet.is_pet_spayed_neutered">{{ pet.willing_to_spay_neuter == 1 ? "Yes" : "No" }}</div>
                

                <div class="text-caption pt-2">Does the pet have any known health issues?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.health_issues }}</div>

                
                <div class="float-right mt-n4 primary--text text-caption"  style="cursor:pointer" v-if="!moreInfo" @click="moreInfo = !moreInfo">View More...</div>
                
                <v-expand-transition v-if="moreInfo">
                    <div>

                <div class="text-caption pt-2">Pet's current living environment</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.living_situation }}</div>

                <div class="text-caption pt-2">Type of food and how much exercise does it get daily?</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.diet_and_exercise}}</div>

                <div class="text-caption pt-2">Included with Sale/Adoption</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.included_items}}</div>

                <div class="text-caption pt-2">Willing to meet the buyer/adopter halfway</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.willing_to_meet == 1 ? 'Yes' : 'No'}}</div>

                <div class="text-caption pt-2">Why rehoming the pet</div>
                <div class="text-caption black--text font-weight-bold">{{ pet.rehoming_reason }}</div>

            </div>
            </v-expand-transition>    
            
            </v-sheet>
            </div>
            

            <div>

                <v-divider class="my-3" style="border-color: #eee"></v-divider>

                <v-row no-gutters>
                    <v-col>
                        <div class="text-overline grey--text">
                    {{ pet.user.category == 'breeder' ? 'Breeder' : 'Owner' }}
                </div>
                   </v-col>
                <!--  <v-col class="text-right" v-if="pet.user.xid != this.$store.getters.userDetails.xid">
                    <v-btn @click="reportDialog=true;" depressed outlined rounded small color="error"><v-icon small >mdi-alert-circle</v-icon>&nbsp;Report Scam</v-btn>
                </v-col> -->
                <div class="pb-3 pt-2 mt-n2 d-flex flex-row align-center justify-end" v-if="pet.user.xid != this.$store.getters.userDetails.xid">
                   
                   <scam-report-button :user="pet.user"></scam-report-button>
                   <block-user-button :user="pet.user"></block-user-button>
                   
               </div>
            </v-row>

                

                <v-row no-gutters >
                    <v-col cols="2" class="d-flex align-start">
                        <v-avatar style="border: 2px solid #fff" color="rgba(126, 13, 231, 0.1)">
                            <v-img v-if="pet.user.photo" :src="pet.user.photo"></v-img>
                            <v-icon color="primary" v-else size="28">$vuetify.icons.account-icon</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="10" class="pl-2">
                        <div class="text-subtitle-2">{{pet.user.name}}
                            <!-- <v-icon small color="blue" v-show="pet.user.phone_verified && pet.user.email_verified">mdi-check-decagram</v-icon> -->
                        </div>
                        <div class="text-caption">
                            {{location}}
                        </div>


                        <v-row no-gutters class="py-1">
                            <v-col>
                                <v-chip v-if="pet.user.phone_verified" small label class="ma-1" color="#eef9e9" text-color="green">Phone Verified</v-chip>
                                <v-chip label v-if="pet.user.email_verified" small class="ma-1" color="#eef9e9" text-color="green">Email Verified</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                </v-row>

              
                <v-divider class="my-3" style="border-color: #eee"></v-divider>
     

            </div>

            <v-row no-gutters>
                <v-col style="font-size:10px !important" class="text-caption grey--text">Published: {{ pet.created_ago }}</v-col>
                <v-col style="font-size:10px !important" class="text-caption grey--text text-right">Last Active: {{ pet.user.last_active_ago }}</v-col>
            </v-row>
        </v-card-text>

        </div>

        <v-alert border="left" text dense color="error" class="mt-2 mb-0" v-if="pet.user.xid != this.$store.getters.userDetails.xid && pet.user.banned">
                                   <span class="text-caption font-weight-medium">Account Banned</span>
                        </v-alert>
                        


        <v-card-actions class="primary justify-center rounded-t-xl " v-else-if="pet.user.xid != this.$store.getters.userDetails.xid && !hideSection">

            <v-row no-gutters align="center" justify="center">
                <v-col v-if="pet.user.phone_verified" class="text-center">
                    <v-btn dark text @click.stop="initDirectConnect" class="mr-2" v-if="!$store.getters.isConnection(pet.user.xid)">
                        <v-icon left>mdi-phone</v-icon> Call
                    </v-btn>

                    <v-btn dark text @click.stop="addConnection" :loading="connecting" class="mr-2" v-if="$store.getters.isConnection(pet.user.xid)">
                        <v-icon left>mdi-phone-check</v-icon> Call
                    </v-btn>
                </v-col>
                <v-col class="text-center">

                    <v-btn dark text @click.stop="chat" v-if="pet.conversation && $store.getters.getConversation(pet.conversation.xid)" :loading="checking">
                        <v-badge color="secondary" :content="this.$store.getters.getConversation(pet.conversation.xid).unread_messages_count" :value="this.$store.getters.getConversation(pet.conversation.xid).unread_messages_count" overlap>
                            <v-icon left>mdi-chat-processing</v-icon> Message
                        </v-badge>
                    </v-btn>

                    <v-btn dark text @click.stop="chat" v-else :loading="checking">
                        <v-icon left>mdi-chat-processing</v-icon> Message
                    </v-btn>

                </v-col>

            </v-row>

        </v-card-actions>

        <v-card-actions class="primary justify-center rounded-t-xl mild-shadow-primary" v-else-if="!hideSection">

            <v-btn dark text large @click.stop="showConversation=true">MESSAGES</v-btn>

        </v-card-actions>
     

    </v-card>

    <chat v-if="$store.getters.chatModel && $store.getters.activeChatIdentifier === chatIdentifier" :pet="pet" :user="pet.user" :conversation="petConversation" :key="componentKey" :identifier="chatIdentifier"/>
    <conversations v-model="showConversation" :pet="pet" />

    <v-dialog v-model="connectConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p>
                    Are you sure you want to use a Direct Connect to reach {{pet.name}}
                </p>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="grey" @click="connectConfirm = false">No</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addConnection" :loading="connecting" :disabled="connecting">Yes, Connect me</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="connectContact" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-center">

                <div class="pa-4">
                    <v-avatar size="64" style="border: 2px solid #7E0DE7">
                        <v-img v-if="pet.user.photo" :src="pet.user.photo"></v-img>
                        <v-icon color="#7E0DE7" v-else size="36">$vuetify.icons.account-icon</v-icon>
                    </v-avatar>
                </div>

                <div class="text-subtitle-1 font-weight-medium primary--text">{{pet.user.name}}</div>
                <!-- <div class="text-caption text-truncate pb-4">
                    <v-icon x-small color="grey">
                        mdi-map-marker
                    </v-icon> {{pet.location_description}}
                </div> -->

                <div class="text-subtitle-2 pt-2"><a :href="'mailto:'+contactInfo.email">{{contactInfo.email}}</a> <v-icon small color="green" v-show="pet.user.email_verified">mdi-check-decagram</v-icon></div>

                <div class="text-subtitle-2 pt-2"><a :href="'tel:'+contactInfo.phone">{{contactInfo.phone}}</a> <v-icon small color="green" v-show="pet.user.phone_verified">mdi-check-decagram</v-icon></div>

            </v-card-text>

            <v-card-actions>
                <v-span class="text-caption"><v-icon small color="green">mdi-check-decagram</v-icon> = Verified</v-span>

                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="connectContact = false">OK</v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-dialog>
</template>

<script>
import Chat from './Chat.vue'
import Conversations from './Conversations.vue'
import VideoPlayer from '../../layouts/components/VideoPlayer.vue'
import BlockUserButton from './BlockUser.vue'
import ScamReportButton from './ScamReport.vue'

export default {
    name: 'PetPop',
    props: ['value', 'pet'],
    mounted() {
        this.showArrows = (this.pet.photos ? this.pet.photos.length : 0)+ (this.pet.videos ? this.pet.videos.length : 0) > 1
        this.showCarousal = (this.pet.photos && this.pet.photos.length) || (this.pet.videos && this.pet.videos.length) > 0
        //console.log('mounted PetPop')
        this.$nextTick(() => {
            const scrollableContent = this.$refs.scrollableContent;
            if (scrollableContent) {
                scrollableContent.addEventListener('scroll', this.handleScroll);
            }
        });
    },
    watch: {
      "$store.getters.userDetails": function(oldVal,newVal) {
            this.mbu = this.$store.getters.userDetails.myBlocked.map(o => {
              return o.buid;
            });

            this.hideSection = this.mbu.includes(this.pet.user.xid)
      },
    },
    data() {
        return {
            hideSection: false,
            showArrows: false,
            showCarousal: false,
            favLoading: false,
            showChat: false,
            showConversation: false,
            connectConfirm: false,
            connecting: false,
            connectContact: false,
            contactInfo: {},
            infoDialog: false,
            moreInfo: false,
            checking: false,

            snackbar: false,
            snackbar_text: '',
            videoOptions: {
              autoplay: false,
              controls: true
            },
            componentKey:0,
            chatIdentifier: `chat-${this.pet.xid || this.pet.user.xid || Math.random().toString(36).substring(7)}`,

            petConversation: {},
            isScrolled: false, // Track scroll position
        }
    },

    components: {
        Chat,
        Conversations,
        BlockUserButton,
        ScamReportButton,
        VideoPlayer
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        location() {
            return (this.pet.locality ? this.pet.locality + ', ' : (this.pet.city ? this.pet.city + ', ' : '')) + this.pet.state + (this.pet.distance > 0 ? ' (' + Math.round(this.pet.distance) + ' Miles)' : '')
        }
    },
    methods: {
        handleScroll() {
            const carouselSection = this.$refs.carouselSection;
            this.isScrolled = this.$refs.scrollableContent.scrollTop > carouselSection.clientHeight;
            },
        showMoreInfo()
        {
            this.moreInfo = !this.moreInfo;
        },

        handleChange() {
          const videos = document.querySelectorAll('.video-js');
          videos.forEach(video => {
              video.player.pause();
          });
        },
        getVideoUrl(item) {
            let url='https://api.dyntube.com/v1/live/videos/'+item.video_key+'.m3u8';
            return url;
        },
        markFavourite() {
            let for_id = this.$store.getters.myPetID == null ? 'user' : this.$store.getters.myPetID;

            if (typeof (this.pet.fav_for) !== 'undefined') {
                for_id = this.pet.fav_for
            }

            let pet_id = this.pet.xid;

            this.favLoading = true;
            this.$store.dispatch('markFavorite', {
                    for_id,
                    pet_id,
                }).then(() => {
                    this.favLoading = false;
                })
                .catch(err => {
                    this.favLoading = false;
                    //this.snackbar_text = "Oops, we encountered an error";
                    //this.snackbar = true;
                });

        },

        initDirectConnect() {

            if (this.$store.getters.userDetails.dconnects > 0) {
                this.connectConfirm = true;
            } else {
                this.$store.dispatch('showDirectConnectPop', true);
            }

        },

        addConnection() {
            let xid = this.pet.user.xid
            this.connecting = true

            this.$store.dispatch('addConnection', {
                    xid
                })
                .then((resp) => {
                    this.contactInfo = resp.data;
                    this.connecting = false;
                    this.connectConfirm = false;
                    this.connectContact = true;
                })
                .catch(err => {
                    this.connecting = false;
                    this.snackbar_text = "Error getting connection";
                    this.snackbar = true;
                });

        },
        chat()
        {
            let fpid = this.$store.getters.myPetID ? this.$store.getters.myPetID : null;
            if (typeof (this.pet.fav_for) !== 'undefined') {
                fpid = this.pet.fav_for? this.pet.fav_for : null;
            }
            let tpid = this.pet.xid
            let fuid = this.$store.getters.userDetails.xid
            let tuid = this.pet.user.xid;
            this.checking = true;

          this.$store.dispatch('getConversationBy', {
            tpid,
            fpid,
            fuid,
            tuid
          })
            .then((resp) => {
                if (!this.pet.conversation) {
                  this.petConversation = resp.data;
                } else {
                  this.petConversation = this.pet.conversation;
                }

              this.checking = false;
              this.$store.dispatch('showChatModel', true);
              this.$store.dispatch('setActiveChatIdentifier', this.chatIdentifier);
              this.componentKey++;
            }).catch(err => {
                this.checking = false;
                this.snackbar_text = "Oops, Unable to get the conversation details";
                this.snackbar = true;
            });
        },

        close()
        {
            this.show=false;

            if (this.$route.path.startsWith('/pet')) {
                if(this.$store.getters.previousRoute == null)
                {
                    this.$router.replace('/home');
                }
                else
                {
                    this.$router.go(-1);
                }
                    
            } 
        }
    }
}
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 10px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  color: white;
}
</style>