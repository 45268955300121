<template>
<v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable max-width="450" transition="dialog-transition" :retain-focus="false">

    <v-card class="adopterpop">
        <v-btn dark @click.stop="close" fab top left absolute depressed class="mt-11" style="background-color:rgba(0,0,0,0.40);" v-if="$vuetify.breakpoint.xsOnly">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn dark @click.stop="close" fab top right absolute depressed class="mt-11" style="background-color:rgba(0,0,0,0.40);" v-else>
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-row no-gutters class="mb-8">
            <v-col cols="12">

                <div class="primary d-flex align-end justify-center" style="align:center; width:100%; height:180px" >

                <v-avatar size="160" class="mb-n8 mild-shadow" color="white" style="border:4px solid white">
                    <v-img v-if="adopter.user.photo" :src="adopter.user.photo"></v-img>
                    <v-icon size="80" color="primary" v-else>$vuetify.icons.account-icon</v-icon>
                </v-avatar>

                </div>

            </v-col>
        </v-row>

        <v-row no-gutters class="px-4 pt-4 pb-0" align="center">
            <v-col cols="12">
                    <h2>{{adopter.user.name}} <v-icon v-show="adopter.is_premium" size="20" color="#d4af37" class="pb-1">mdi-crown</v-icon> </h2> 
            </v-col>
            <!-- <v-col cols="3" class="text-right">

                <v-btn icon @click="markFavouriteAdopter" :loading="favLoading">
                    <v-icon color="red" v-if="adopter.is_favorite">
                        mdi-heart
                    </v-icon>
                    <v-icon v-else color="grey lighten-1">
                        mdi-heart-outline
                    </v-icon>
                </v-btn>

            </v-col> -->
            <v-col cols="12">
                <div class="text-caption">{{location}}</div>
            </v-col>
        </v-row>

        <v-row no-gutters class="py-1 px-3">
            <v-col>
                <v-chip v-if="adopter.user.phone_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-cellphone-check</v-icon>
                                    Phone Verified</v-chip>

                    <v-chip v-if="adopter.user.email_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-email-check-outline</v-icon>
                                    Email Verified</v-chip>
                    <v-chip v-if="adopter.user.video_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-video-check-outline</v-icon>
                                    Video Verified</v-chip>
            </v-col>
        </v-row>

        

        <v-row no-gutters class="mt-2 px-2" align="center" justify="center">

            <v-col class="pa-2">
                    <div class="text-caption grey--text pb-1 font-weight-medium">
                    Preferences:
                    </div>
                    <div>
                        <v-chip small class="ma-1" > {{adopter.pet_type.type}} </v-chip>
                        <v-chip small class="ma-1" > {{adopter.age == 'B' ? 'Baby ' : (adopter.age == 'Y' ? 'Young ' : (adopter.age == 'A' ? 'Adult ' : ( adopter.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                        <v-chip small class="ma-1" > {{adopter.gender == 'M' ? 'Male ' : (adopter.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                        <v-chip small class="ma-1" > {{adopter.pet_size ? adopter.pet_size.size+' size ':'Any size'}} </v-chip>
                        <v-chip small class="ma-1" > {{adopter.pet_breed ? adopter.pet_breed.breed+' ':'Any breed'}} </v-chip>
                        <v-chip small class="ma-1" > {{adopter.available_for ? 'For '+ adopter.available_for: 'For sale/adoption'}} </v-chip>
                        <v-chip small class="ma-1" > {{'Within ' + adopter.pref_distance + ' Miles'}} </v-chip>
                    </div>
            </v-col>

        </v-row>

        <v-card-text class="pa-4 black--text">

            <v-row justify="center" no-gutters>
                <v-col cols="12" class="justify-center text-pre-wrap text-caption">

                    {{adopter.description}}

                </v-col>
            </v-row>

            <div v-if="adopter.user.user_adopter && adopter.user.user_adopter.status == 'verified'">
                
                    <v-sheet  class="pa-4 my-4 rounded-lg" style="background-color: #f2f2ff;">
                        
                       <!--  <div class="float-right primary--text text-caption" style="cursor:pointer" v-if="moreInfo" @click="moreInfo = !moreInfo">View Less</div>
                     -->
                        <div class="text-caption">Where does the member live?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.residence_type }}</div>

                    <div class="text-caption pt-2">Does the member own or rent their home?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.owns_home == 1 ? "Own" : "Rented" }}</div>

                    
                    <div class="float-right mt-n4 primary--text text-caption"  style="cursor:pointer" v-if="!moreInfo" @click="moreInfo = !moreInfo">View More...</div>
                    
                    <v-expand-transition v-if="moreInfo">
                        <div>

                    <div class="text-caption pt-2" v-if="adopter.user.user_adopter.owns_home != 1">Does the member have permission from their landlord to have a pet?</div>
                    <div class="text-caption black--text font-weight-bold" v-if="adopter.user.user_adopter.owns_home != 1">{{ adopter.user.user_adopter.has_landlord_permission == 1 ? "Yes" : "No" }}</div>

                    <div class="text-caption pt-2">Has the member ever owned a pet before?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.has_previous_pet_experience == 1 ? "Yes" : "No" }}</div>

                    <div class="text-caption pt-2" v-if="adopter.user.user_adopter.has_previous_pet_experience == 1">What type of pet(s) did the member own and for how long?</div>
                    <div class="text-caption black--text font-weight-bold" v-if="adopter.user.user_adopter.has_previous_pet_experience == 1">{{ adopter.user.user_adopter.previous_pet_details || '-' }}</div>

                    <div class="text-caption pt-2">How active is the member’s lifestyle? Do they enjoy outdoor activities?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.activity_level }}</div>

                    <div class="text-caption pt-2">How much time is the member willing to dedicate to caring for a pet each day?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.time_commitment }}</div>

                    <div class="text-caption pt-2">What is the member looking for in a pet?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.expectations }}</div>

                    <div class="text-caption pt-2">Is the member willing to invest time and resources in training their pet?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.willing_to_train  == 1 ? "Yes" : "No" }}</div>

                    <div class="text-caption pt-2">How much is the member willing to spend on pet care expenses like food, vet visits, grooming, etc.?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.pet_care_budget }}</div>

                    <div class="text-caption pt-2">Does the member have a fenced yard?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.has_fenced_yard == 1 ? "Yes" : "No" }}</div>

                    <div class="text-caption pt-2">How does the member plan to exercise their pet?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.exercise_plan }}</div>

                    <div class="text-caption pt-2">Does the member have other pets in the household?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.has_other_pets == 1 ? "Yes" : "No" }}</div>

                    <div class="text-caption pt-2" v-if="adopter.user.user_adopter.has_other_pets == 1">Describe the other pets</div>
                    <div class="text-caption black--text font-weight-bold" v-if="adopter.user.user_adopter.has_other_pets == 1">{{ adopter.user.user_adopter.other_pet_details || '-' }}</div>

                    <div class="text-caption pt-2">Does the member have any allergies?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.has_allergies  == 1 ? "Yes" : "No" }}</div>

                    <div class="text-caption pt-2" v-if="adopter.user.user_adopter.has_allergies  == 1">Specify the member’s allergies</div>
                    <div class="text-caption black--text font-weight-bold" v-if="adopter.user.user_adopter.has_allergies  == 1">{{ adopter.user.user_adopter.allergies || '-' }}</div>

                    <div class="text-caption pt-2">Who will be the primary caregiver for the pet?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.primary_caregiver }}</div>

                    <div class="text-caption pt-2">What are the member’s plans if they go on vacation or are unable to care for the pet?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.vacation_care_plan }}</div>

                    <div class="text-caption pt-2">Is there anything else the member would like to share about themselves or their interest in getting a pet?</div>
                    <div class="text-caption black--text font-weight-bold">{{ adopter.user.user_adopter.additional_information || '-' }}</div>
                </div>
                </v-expand-transition>    
                
                </v-sheet>
                </div>
           
            

            

        </v-card-text>

        

        <div class="pa-4 d-flex flex-row align-center justify-start">
            <scam-report-button :user="adopter.user"></scam-report-button>
            <block-user-button :user="adopter.user"></block-user-button>
        </div>

        <v-row no-gutters>
                  <v-col style="font-size:10px !important" class="text-caption grey--text pl-4 pa-2">Last Active: {{ adopter.user.last_active_ago }}</v-col>
            </v-row>


        <v-card-actions class="primary justify-center rounded-t-xl" v-if="adopter.user.xid != this.$store.getters.userDetails.xid && !hideSection">

            <v-row no-gutters align="center" justify="center">
                <v-col class="text-center" v-if="adopter.user.phone_verified || adopter.user.email_verified">
                    
                    <v-btn dark text @click.stop="addConnection" :loading="connecting" class="mr-2" v-if="$store.getters.isConnection(adopter.user.xid)">
                        <v-icon left >mdi-phone-check</v-icon> Call
                    </v-btn>

                    <v-btn dark text @click.stop="initDirectConnect" class="mr-2" v-else>
                        <v-icon left>mdi-phone</v-icon> Call
                    </v-btn>

                </v-col>
                <v-col class="text-center">
                    <v-btn dark text @click.stop="chat"  v-if="adopter.conversation && $store.getters.getConversation(adopter.conversation.xid)" :loading="checking">
                        <v-badge color="secondary"  :content="this.$store.getters.getConversation(adopter.conversation.xid).unread_messages_count" :value="this.$store.getters.getConversation(adopter.conversation.xid).unread_messages_count" >
                        <v-icon left>mdi-chat-processing</v-icon> Message
                        </v-badge>
                    </v-btn>

                    <v-btn dark text @click.stop="chat" v-else-if="!hideSection" :loading="checking">
                        <v-icon left>mdi-chat-processing</v-icon> Message
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-actions>


    </v-card>

    <chat v-if="$store.getters.chatModel && $store.getters.activeChatIdentifier === chatIdentifier" :user="adopter.user" :conversation="adopterConversation" :key="componentKey"/>
    <!-- <conversations v-model="showConversation" :adopter="adopter" /> -->

    <v-dialog v-model="connectConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p>
                    Are you sure you want to use a Direct Connect to reach {{adopter.user.name}}
                </p>
            </v-card-text>

            <v-card-actions>
                    <v-btn text color="grey" @click="connectConfirm = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="addConnection" :loading="connecting" :disabled="connecting">Yes, Connect me</v-btn>
                </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="connectContact" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-center">

                <div class="pa-4">
                <v-avatar size="64" style="border: 2px solid #7E0DE7">
                        <v-img v-if="adopter.user.photo" :src="adopter.user.photo"></v-img>
                        <v-icon color="#7E0DE7" v-else>$vuetify.icons.account-icon</v-icon>
                </v-avatar>
                </div>

                <div class="text-subtitle-1 font-weight-medium primary--text">{{adopter.user.name}}</div>
                <!-- <div class="text-caption text-truncate pb-4">
                    <v-icon x-small color="grey">
                        mdi-map-marker
                    </v-icon> {{adopter.location_description}}
                </div> -->

                <div class="text-subtitle-2 pt-2"><a :href="'mailto:'+contactInfo.email">{{contactInfo.email}}</a><v-icon small color="green" v-show="adopter.user.email_verified">mdi-check-decagram</v-icon></div>

                <div class="text-subtitle-2 pt-2"><a :href="'tel:'+contactInfo.phone">{{contactInfo.phone}}</a> <v-icon small color="green" v-show="adopter.user.phone_verified">mdi-check-decagram</v-icon></div>
                
            </v-card-text>

            <v-card-actions>
                <v-span class="text-caption"><v-icon small color="green">mdi-check-decagram</v-icon> = Verified</v-span>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="connectContact = false">OK</v-btn>
                   
            </v-card-actions>

        </v-card>
    </v-dialog>
  
    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-dialog>
</template>

<script>
import Chat from './Chat.vue'
import Conversations from './Conversations.vue'
import BlockUserButton from './BlockUser.vue'
import ScamReportButton from './ScamReport.vue'

export default {
    name: 'AdopterPop',
    props: ['value', 'adopter'],
    mounted() {
        //console.log(this.adopter)
    },
    data() {
        return {
            hideSection: false,
            favLoading: false,
            showChat: false,
            showConversation: false,
            connectConfirm: false,
            connecting: false,
            connectContact: false,
            contactInfo:{},
            reportDialog: false,
            reportMessage: '',
            checking: false,

            snackbar: false,
            snackbar_text: '',
            componentKey:0,
            chatIdentifier: `chat-${this.adopter.user.xid || Math.random().toString(36).substring(7)}`,
            moreInfo: false,

            adopterConversation: {}
        }
    },
    watch: {
      "$store.getters.userDetails": function(oldVal,newVal) {
        this.mbu = this.$store.getters.userDetails.myBlocked.map(o => {
          return o.buid;
        });

        this.hideSection = this.mbu.includes(this.adopter.user.xid)
      },
    },
    components: {
        Chat,
        BlockUserButton,
        ScamReportButton,
        Conversations
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        location()
        {
            return (this.adopter.locality ? this.adopter.locality+', ' : (this.adopter.city ? this.adopter.city+', ' : '')) + this.adopter.state + (this.adopter.distance > 0 ? ' ('+ Math.round(this.adopter.distance) +' Miles)' :'')
        }
    },
    methods: {

        showMoreInfo()
        {
            this.moreInfo = !this.moreInfo;
        },

        /* markFavouriteAdopter() {
            let for_id = this.$store.getters.myPetID == null ? 'user' : this.$store.getters.myPetID;

            if (typeof (this.adopter.fav_for) !== 'undefined') {
                for_id = this.adopter.fav_for.fav_for_xid
            }

            let adopter_id = this.adopter.xid;

            this.favLoading = true;
            this.$store.dispatch('markFavoriteAdopter', {
                    for_id,
                    adopter_id,
                }).then(() => {
                    this.favLoading = false;
                })
                .catch(err => {
                    this.favLoading = false;
                    //this.snackbar_text = "Oops, we encountered an error";
                    //this.snackbar = true;
                });

        }, */

        initDirectConnect() {

            if(this.$store.getters.userDetails.dconnects > 0)
            {
                this.connectConfirm = true;
            }
            else
            {
                this.$store.dispatch('showDirectConnectPop', true);
            }

        },

        addConnection()
        {
            let xid = this.adopter.user.xid
            this.connecting = true

            this.$store.dispatch('addConnection', {xid})
                .then((resp) => {
                    this.contactInfo = resp.data;
                    this.connecting = false;
                    this.connectConfirm = false;
                    this.connectContact = true;
                })
                .catch(err => {
                    this.connecting = false;
                    this.snackbar_text = "Error getting connection";
                    this.snackbar = true;
                });

        },

        chat()
        {
            let adopter_xid = this.adopter.user.xid

            let fuid = this.$store.getters.userDetails.xid
            let tuid = this.adopter.user.xid;
            let fpid = this.$store.getters.myPetID;
            let tpid = null;
            this.checking = true;

            this.$store.dispatch('getConversationBy', {
              fuid,
              tuid,
              fpid,
              tpid,
            })
              .then((resp) => {
                  if (!this.adopter.conversation) {
                    this.adopterConversation = resp.data;
                  } else {
                    this.adopterConversation = this.adopter.conversation;
                  }

                  this.checking = false;
                  this.$store.dispatch('showChatModel', true);
                  this.$store.dispatch('setActiveChatIdentifier', this.chatIdentifier);
                  this.componentKey++;
              }).catch(err => {
                //console.log(err);
                this.checking = false;
                this.snackbar_text = "Oops, Unable to get the conversation details";
                this.snackbar = true;
              });
        },
        close()
        {
            this.show=false;

            if (this.$route.path.startsWith('/adopter')) {
                if(this.$store.getters.previousRoute == null)
                {
                    this.$router.replace('/home');
                }
                else
                {
                    this.$router.go(-1);
                }
                    
            } 
        }
    }
}
</script>
