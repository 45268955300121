import Vue from 'vue'
import axios from 'axios'

export default {
    state: {

        myPetID: null,
        petPrefID: null,
        selectedPet: {
            name: '',
            photos: []
        },

    },

    getters: {

        myPetID: state => state.myPetID,
        myPetDetails: (state, rootGetters) => (pet_id) => {
            return rootGetters.userDetails.pets.find(
                pet => pet.xid === pet_id
            );
        },
        selectedPet: state => state.selectedPet,
        petPrefID: state => state.petPrefID,

    },

    mutations: {

        resetPetState(state)
        {
            state.myPetID = null
            state.petPrefID = null
            state.selectedPet = {
                name: '',
                photos: []
            }
        },

        setMyPetID(state, payload) {
            state.myPetID = payload;
        },

        setPetPrefID(state, payload) {
            state.petPrefID = payload;
        },

        setSelectedPet(state, payload) {
            state.selectedPet = payload;
        },

    },
    actions: {

        setSelectedPet({ commit }, pet) {
            commit("setSelectedPet", pet);
        },

        setMyPetID({ commit }, petID) {
            commit("setMyPetID", petID);
        },

        setPetPrefID({ commit }, prefID) {
            commit("setPetPrefID", prefID);
        },

        newPet({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/pet", data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        }); 
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        editPet({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/pet/" + data.xid, data.formData)
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        }); 
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        newMatches({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/new-matches/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        // same as petFromId but this will update my pets
        getPetDetails({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/pet/"+xid)
                    .then(resp => {
                        commit('updatePet', resp.data, { root: true })
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        petFromId({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/pet/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        petFromSlug({ commit }, slug) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/pets/"+slug)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addVideo({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/video", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deleteVideo({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/video/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addProfileVideo({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/pet/profile/verification", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deleteProfileVideo({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/pet/profile/verification/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addPedigreeCertificate({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/pet/pedigree", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deletePedigreeCertificate({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/pet/pedigree/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addDnaReport({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/pet/dnareport", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deleteDnaReport({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/pet/dnareport/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addPhoto({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/photo", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deletePhoto({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/photo/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        addAvatar({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/avatar", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addCertificate({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/certificate", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        showPet({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/show-pet", data)
                    .then(resp => {
                        commit('updatePet', resp.data, { root: true })
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        

        
        setPetFilters({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/filters", data)
                    .then(resp => {
                        commit('updatePet', resp.data, { root: true })
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        savePetExtraInfo({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/pet-extra-info", data)
                    .then(resp => {
                        //commit('updatePet', resp.data, { root: true })
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        isMyPet({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/is-pet/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        unpublishPet({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/unpublish", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        updateRating({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/rating", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        submitFeedback({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/feedback", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        blockFollowup({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/block", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        

        unpublishPref({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/pref-unpublish", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        updatePrefRating({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/pref-rating", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        submitPrefFeedback({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/pref-feedback", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        blockPrefFollowup({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/status-update/pref-block", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        



    }
};