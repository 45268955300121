<template>
    <div>
        <v-dialog v-model="this.$store.getters.categoryPop" persistent scrollable max-width="450" :retain-focus="false">

            <v-card class="pb-2">
                <v-card-text class="pt-6 pb-4">

                    <div class="text-left mb-4">

                        <div class="text-subtitle-1 black--text  mb-6">Choose Your Petmeetly Journey</div>
                    </div>

                    <v-item-group v-model="selectedCategory">
                        <v-row>
                            <v-col cols="6" class="d-flex align-start justify-center" v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="adopter">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Adopter
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking to adopt a pet from a shelter, rescue, or individual.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-start justify-center" v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="buyer">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Buyer
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking to purchase a pet from a breeder or individual.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-start justify-center">
                                <v-item v-slot="{ active, toggle }" value="mate-seeker">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Mate Seeker
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking for a mate for my pet for breeding purposes.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-start justify-center" v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="breeder">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Breeder
                                        </div>
                                        <div class="text-left text-caption">
                                            I responsibly breed pets and am looking for loving homes for my offspring.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-start justify-center" v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="shelter">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Shelter
                                        </div>
                                        <div class="text-left text-caption">
                                            We are an organization dedicated to rescuing and rehoming animals in need.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-start justify-center" v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="individual">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Individual
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking to rehome my pet.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                </v-card-text>

                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="pl-8" @click="saveCategory" :loading="saving">CONTINUE <v-icon
                            right>mdi-chevron-right</v-icon></v-btn>

                </v-card-actions>

            </v-card>

        </v-dialog>



        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>

export default {

    mounted() {

    },
    data() {
        return {

            selectedCategory: null,
            saving: false,


            snackbar: false,
            snackbar_text: '',
        }
    },



    methods: {



        saveCategory() {

if (this.selectedCategory == '' || this.selectedCategory == null) {
    this.snackbar_text = "Please select a category";
    this.snackbar = true;
}
else {

    let category = this.selectedCategory;
    this.saving = true;

    this.$store.dispatch('setUserCategory', { category })
        .then((resp) => {

            this.saving = false;
            
            if (category == 'adopter' || category == 'buyer') {
                this.$router.replace('adopter-questionnaire');
            }
            else if (category == 'breeder') {
                this.$router.replace('breeder-questionnaire');
            }
            else if (category == 'shelter') {
                this.$router.replace('shelter-questionnaire');
            }

            this.$store.dispatch('showCategoryPop', false)

        })
        .catch(err => {

            this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
            this.snackbar = true;
            this.saving = false;
        });

}


}


        




    }
}
</script>