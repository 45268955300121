<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">

                <div>

                    <div class="text-h6 font-weight-regular py-4 grey--text">
                        Tell us a bit about yourself...
                    </div>

                    <v-row justify="center" no-gutters>
                        <v-col cols="12" class="justify-center">

                          <v-row no-gutters>
                            <v-col class="px-2">
                              <v-progress-linear :color="step > 0 ? 'primary' : '#ccc'"
                                                 :value="(step / maxSteps) * 100" rounded height="8"></v-progress-linear>
                            </v-col>
                          </v-row>
                        </v-col>
                    </v-row>

                  <div v-show="step == 1" class="mb-4">
                    <v-row class="my-2 ">
                        <v-col cols="12">
                            <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Breeder Information</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Full Name</div>
                            <v-text-field v-model="full_name" dense outlined hide-details flat type="text" placeholder="Full name"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Kennel Name (if applicable)</div>
                            <v-text-field v-model="kennel_name" dense outlined hide-details flat type="text" placeholder="Kennel Name"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Website:</div>
                            <v-text-field v-model="website" dense outlined hide-details flat type="text" placeholder="http://www.example.com"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Social Media Links:</div>
                            <v-textarea v-model="social_media_links" dense outlined hide-details flat :placeholder="social_media_placeholder"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Years of Breeding Experience:</div>
                            <v-text-field v-model="years_of_experience" dense outlined hide-details flat type="number" placeholder="0"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Breeds You Specialize In:</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="Afador, Bassador, Beabull, etc..." v-model="specialized_breeds"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Certifications or Memberships:</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="e.g., Kennel Club, Breed-Specific Organizations" v-model="certifications"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-left"><v-btn depressed :to="'/home'" >
                        Cancel
                      </v-btn></v-col>
                      <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                        Continue
                      </v-btn></v-col>
                    </v-row>
                  </div>

                  <div v-show="step == 2" class="mb-4">
                    <v-row class="my-2 ">
                        <v-col cols="12">
                            <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Breeding Practices</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Describe your approach to breeding and your goals for your breeding program:</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="breeding_philosophy"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">What health tests do you perform on your breeding animals?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="Please provide specific tests and expected results." v-model="health_testing_protocols"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Average Litter Size:</div>
                            <v-text-field v-model="average_litter_size" dense outlined hide-details flat type="number" placeholder="0"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">How often do you breed your females?</div>
                            <v-text-field v-model="breeding_frequency" dense outlined hide-details flat type="text" placeholder=""></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Describe how you socialize your puppies/kittens during their first weeks.</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="puppy_socialization"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">What veterinary care do you provide for your breeding animals and puppies/kittens?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="veterinary_care"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Describe the living conditions and housing for your breeding animals.</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="living_conditions"></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                          Back
                        </v-btn></v-col>
                        <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                          Continue
                        </v-btn></v-col>
                      </v-row>
                  </div>

                  <div v-show="step == 3" class="mb-4">
                    <v-row class="my-2 ">

                    <v-col cols="12">
                            <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Animal Welfare and Ethics</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">How do you ensure the physical and mental well-being of your breeding animals?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="animal_welfare_commitment"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">What ethical considerations do you take into account in your breeding practices?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="ethical_considerations"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Do you require buyers to spay/neuter their pets?</div>
                            <v-radio-group v-model="requires_spay_neuter" class="mt-0" row hide-details>
                                <v-radio label="Yes" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">What is your policy if a buyer can no longer care for the pet?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="rehoming_policy"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Do you offer any health guarantees or contracts to buyers?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="guarantee_contract"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                        Back
                      </v-btn></v-col>
                      <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                        Continue
                      </v-btn></v-col>
                    </v-row>
                  </div>

                  <div v-show="step == 4" class="mb-4">
                    <v-row class="my-2 ">
                        <v-col cols="12">
                          <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Sales and Placement</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">How do you determine the price of your puppies/kittens?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="pricing_details"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Do you have a waiting list for your puppies/kittens?</div>
                            <v-radio-group v-model="has_waiting_list" class="mt-0" row hide-details>
                                <v-radio label="Yes" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">How do you screen potential buyers to ensure they are suitable homes for your animals?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="screening_process"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">What kind of support do you offer to buyers after they take home a puppy/kitten?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="post_sale_support"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                        Back
                      </v-btn></v-col>
                      <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)">
                        Continue
                      </v-btn></v-col>
                    </v-row>
                  </div>

                  <div v-show="step == 5" class="mb-4">
                    <v-row class="my-2 ">
                        <v-col cols="12">
                            <div class="text-overline mt-4 grey lighten-3  pl-2 py-1 rounded">Additional Information</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Do you participate in any dog shows or competitions? (If applicable)</div>
                            <v-radio-group v-model="participates_in_shows" class="mt-0" row hide-details>
                                <v-radio label="Yes" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to provide photos or videos of your kennel and breeding animals?</div>
                            <v-radio-group v-model="willing_to_provide_media" class="mt-0" row hide-details>
                                <v-radio label="Yes" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Is there any additional information you would like to share about your breeding program?</div>
                            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="additional_information"></v-textarea>
                        </v-col>

                        <v-col cols="12">

                            <div>
                                <p class="text-caption">By submitting, you acknowledge that the information provided is
                                    accurate and complete to the best of your knowledge. You understand that Petmeetly
                                    and/or the pet provider may conduct additional checks or interviews as part of the
                                    vetting process.</p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text-left"><v-btn depressed @click="prevStep(step)">
                        Back
                      </v-btn></v-col>
                      <v-col class="text-right"><v-btn color="primary" @click="nextStep(step)" :loading="submitting" :disabled="submitting">
                        Submit
                      </v-btn></v-col>
                    </v-row>
                  </div>
                </div>

                <v-snackbar v-model="snackbar">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>

                <v-overlay :value="dataLoading">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>

            </v-col>
        </v-row>
    </v-container>


</template>

<script>
export default {
    name: 'BreederQuestionnaire',


    mounted() {


        if (this.$store.getters.userDetails.category !== 'breeder') {
            this.$router.replace('home');
        }
        else {
            this.getBreederQuestionnaire();
        }

    },

    data() {
        return {
          step: 1,
          maxSteps: 5,

          social_media_placeholder : `facebook:
instagram:
twitter:`,

            full_name: '',
            kennel_name: '',
            website: '',
            social_media_links: '',
            years_of_experience: '',
            specialized_breeds: '',
            certifications: '',
            breeding_philosophy: '',
            health_testing_protocols: '',
            average_litter_size: '',
            breeding_frequency: '',
            puppy_socialization: '',
            veterinary_care: '',
            living_conditions: '',
            animal_welfare_commitment: '',
            ethical_considerations: '',
            requires_spay_neuter: '',
            rehoming_policy: '',
            guarantee_contract: '',
            pricing_details: '',
            has_waiting_list: '',
            screening_process: '',
            post_sale_support: '',
            participates_in_shows: '',
            willing_to_provide_media: '',
            additional_information: '',

            submitting: false,
            dataLoading: false,
            snackbar: false,
            snackbar_text: '',
        }
    },

    methods: {

        nextStep(x) {
          this.$vuetify.goTo(0);

          if (x == 1) {
            if (this.full_name.trim() === ''
                || this.kennel_name.trim() === ''
                || this.website.trim() === ''
                || this.social_media_links.trim() === ''
                || this.years_of_experience === ''
                || this.specialized_breeds.trim() === ''
                || this.certifications.trim() === ''
            )  {
              this.snackbar_text = "Please provide all details";
              this.snackbar = true;
            } else {
              this.submit();
              this.step = x + 1;
            }
          }

          if (x == 2) {
            if (this.breeding_philosophy.trim() === ''
                || this.health_testing_protocols.trim() === ''
                || this.average_litter_size === ''
                || this.breeding_frequency.trim() === ''
                || this.puppy_socialization.trim() === ''
                || this.veterinary_care.trim() === ''
                || this.living_conditions.trim() === ''
            ) {
            this.snackbar_text = "Please provide all details";
            this.snackbar = true;
          } else {
              this.submit();
              this.step = x + 1;
            }
          }

          if (x == 3) {
            if (this.animal_welfare_commitment.trim() === ''
              || this.ethical_considerations.trim() === ''
              || this.requires_spay_neuter === ''
              || this.rehoming_policy.trim() === ''
              || this.guarantee_contract.trim() === ''
            ) {
              this.snackbar_text = "Please provide all details";
              this.snackbar = true;
            } else {
              this.submit();
              this.step = x + 1;
            }
          }

          if (x == 4) {
            if (this.pricing_details.trim() === ''
                || this.has_waiting_list === ''
                || this.screening_process.trim() === ''
                || this.post_sale_support.trim() === ''
            ) {
              this.snackbar_text = "Please provide all details";
              this.snackbar = true;
            } else {
              this.submit();
              this.step = x + 1;
            }
          }

          if (x == 5) {
            if (this.participates_in_shows === ''
                || this.willing_to_provide_media === ''
            ) {
              this.snackbar_text = "Please provide all details";
              this.snackbar = true;
            } else {
              this.step = x + 1;
              this.submit();
            }
          }
        },

      prevStep(x) {
        this.step = x - 1;
        this.$vuetify.goTo(0);
      },

      getBreederQuestionnaire() {
            this.dataLoading = true;
            this.$store.dispatch('getBreederQuestionnaire').then((resp) => {

                this.dataLoading = false;

                this.full_name = resp.data.full_name == null ? '' : resp.data.full_name;
                this.kennel_name = resp.data.kennel_name == null ? '' : resp.data.kennel_name;
                this.website = resp.data.website == null ? '' : resp.data.website;
                this.social_media_links = resp.data.social_media_links == null ? '' : resp.data.social_media_links;
                this.years_of_experience = resp.data.years_of_experience == null ? '' : resp.data.years_of_experience;
                this.specialized_breeds = resp.data.specialized_breeds == null ? '' : resp.data.specialized_breeds;
                this.certifications = resp.data.certifications == null ? '' : resp.data.certifications;
                this.breeding_philosophy = resp.data.breeding_philosophy == null ? '' : resp.data.breeding_philosophy;
                this.health_testing_protocols = resp.data.health_testing_protocols == null ? '' : resp.data.health_testing_protocols;
                this.average_litter_size = resp.data.average_litter_size == null ? '' : resp.data.average_litter_size;
                this.breeding_frequency = resp.data.breeding_frequency == null ? '' : resp.data.breeding_frequency;
                this.puppy_socialization = resp.data.puppy_socialization == null ? '' : resp.data.puppy_socialization;
                this.veterinary_care = resp.data.veterinary_care == null ? '' : resp.data.veterinary_care;
                this.living_conditions = resp.data.living_conditions == null ? '' : resp.data.living_conditions;
                this.animal_welfare_commitment = resp.data.animal_welfare_commitment == null ? '' : resp.data.animal_welfare_commitment;
                this.ethical_considerations = resp.data.ethical_considerations == null ? '' : resp.data.ethical_considerations;
                this.requires_spay_neuter = resp.data.requires_spay_neuter == null ? '' : resp.data.requires_spay_neuter;
                this.rehoming_policy = resp.data.rehoming_policy == null ? '' : resp.data.rehoming_policy;
                this.guarantee_contract = resp.data.guarantee_contract == null ? '' : resp.data.guarantee_contract;
                this.pricing_details = resp.data.pricing_details == null ? '' : resp.data.pricing_details;
                this.has_waiting_list = resp.data.has_waiting_list == null ? '' : resp.data.has_waiting_list;
                this.screening_process = resp.data.screening_process == null ? '' : resp.data.screening_process;
                this.post_sale_support = resp.data.post_sale_support == null ? '' : resp.data.post_sale_support;
                this.participates_in_shows = resp.data.participates_in_shows == null ? '' : resp.data.participates_in_shows;
                this.willing_to_provide_media = resp.data.willing_to_provide_media == null ? '' : resp.data.willing_to_provide_media;
                this.additional_information = resp.data.additional_information == null ? '' : resp.data.additional_information;
            })
                .catch(err => {
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, Unable to get the details";
                    this.snackbar = true;
                });

        },

        submit() {
              this.submitting = true;

              let formData = new FormData();
              formData.append('full_name', this.full_name);
              formData.append('kennel_name', this.kennel_name);
              formData.append('website', this.website);
              formData.append('social_media_links', this.social_media_links);
              formData.append('years_of_experience', this.years_of_experience);
              formData.append('specialized_breeds', this.specialized_breeds);
              formData.append('certifications', this.certifications);
              formData.append('breeding_philosophy', this.breeding_philosophy);
              formData.append('health_testing_protocols', this.health_testing_protocols);
              formData.append('average_litter_size', this.average_litter_size);
              formData.append('breeding_frequency', this.breeding_frequency);
              formData.append('puppy_socialization', this.puppy_socialization);
              formData.append('veterinary_care', this.veterinary_care);
              formData.append('living_conditions', this.living_conditions);
              formData.append('animal_welfare_commitment', this.animal_welfare_commitment);
              formData.append('ethical_considerations', this.ethical_considerations);
              formData.append('requires_spay_neuter', this.requires_spay_neuter);
              formData.append('rehoming_policy', this.rehoming_policy);
              formData.append('guarantee_contract', this.guarantee_contract);
              formData.append('pricing_details', this.pricing_details);
              formData.append('has_waiting_list', this.has_waiting_list);
              formData.append('screening_process', this.screening_process);
              formData.append('post_sale_support', this.post_sale_support);
              formData.append('participates_in_shows', this.participates_in_shows);
              formData.append('willing_to_provide_media', this.willing_to_provide_media);
              formData.append('additional_information', this.additional_information);
              formData.append('step', this.step);

              this.$store.dispatch('submitBreederQuestionnaire', {
                formData
              })
                  .then((resp) => {
                    this.submitting = false;
                    if(this.step == 6) {
                      this.$router.replace('home');
                    }
                  })
                  .catch(err => {
                    this.submitting = false;
                    this.snackbar_text = "Oops, there is an error while saving. Please retry or contact support";
                    this.snackbar = true;
                  });
        }
    }
}

</script>
