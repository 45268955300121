<template>
    <v-container fluid class="home onboard-bg" :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-8'">

        <div v-if="this.$store.getters.userDetails.name == ''" class="pt-12">

            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>

            </v-row>

        </div>
        <div v-else class="pt-6">

            <div v-if="this.$store.getters.userDetails.latitude == null || this.$store.getters.userDetails.latitude == ''">

            <v-row no-gutters class="d-flex justify-center px-4 mt-16">
                <v-col cols="12" sm="6">


                    <div v-if="!locationPicked">
                        <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" solo flat
                            outlined hide-no-data placeholder="Enter your location" item-text="description"
                            item-value="placeId" clearable autofocus persistent-hint
                            prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails"
                            @click:clear="clearLocation"></v-autocomplete>

                        <div class="py-0 text-center">
                            <v-btn color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                                <v-icon left>mdi-crosshairs-gps</v-icon> {{ gettingLocation ? 'Getting your location' : 'Use my current location' }}
                            </v-btn>
                        </div>


                    </div>


                </v-col>
            </v-row>
        </div>


        <div v-else>

            <v-alert text dense color="error" icon="mdi-shield-account"  border="left" :class="$vuetify.breakpoint.xs ? 'mx-4' : 'mt-n8'" v-if="this.$store.getters.isLoggedIn && 
                (this.$store.getters.userDetails.category === 'adopter' || 
                 this.$store.getters.userDetails.category === 'buyer') &&
                !(this.$store.getters.userDetails.adopter_status === 'submitted' || 
                  this.$store.getters.userDetails.adopter_status === 'verified')">
                <v-row align="center" v-if="$vuetify.breakpoint.xs">
                    <v-col class="text-caption" cols="12">
                        Complete your profile questionnaire to find the perfect pet match!
                    </v-col>
                    <v-col class="d-flex justify-end mt-n2" cols="12">
                        <v-btn depressed small color="error" :to="'/adopter-questionnaire'">Complete Now</v-btn>
                    </v-col>
                </v-row>
                <v-row align="center" v-else>
                    <v-col class="grow text-caption">
                        Complete your profile questionnaire to find the perfect pet match!
                    </v-col>
                    <v-col class="shrink">
                        <v-btn depressed small color="error" :to="'/adopter-questionnaire'">Complete Now</v-btn>
                    </v-col>
                </v-row>
            </v-alert>

            <v-alert text dense color="error" icon="mdi-shield-account"  border="left" :class="$vuetify.breakpoint.xs ? 'mx-4' : 'mt-n8'" v-if="this.$store.getters.isLoggedIn && 
                (this.$store.getters.userDetails.category === 'breeder') &&
                !(this.$store.getters.userDetails.breeder_status === 'submitted' || 
                  this.$store.getters.userDetails.breeder_status === 'verified')">
                <v-row align="center" v-if="$vuetify.breakpoint.xs">
                    <v-col class="text-caption" cols="12">
                        Complete your breeder profile
                    </v-col>
                    <v-col class="d-flex justify-end mt-n2" cols="12">
                        <v-btn depressed small color="error" :to="'/breeder-questionnaire'">Complete Now</v-btn>
                    </v-col>
                </v-row>
                <v-row align="center" v-else>
                    <v-col class="grow text-caption">
                        Complete your breeder profile
                    </v-col>
                    <v-col class="shrink">
                        <v-btn depressed small color="error" :to="'/breeder-questionnaire'">Complete Now</v-btn>
                    </v-col>
                </v-row>
            </v-alert>

            <v-alert text dense color="error" icon="mdi-shield-account"  border="left" :class="$vuetify.breakpoint.xs ? 'mx-4' : 'mt-n8'" v-if="this.$store.getters.isLoggedIn && 
                (this.$store.getters.userDetails.category === 'shelter') &&
                !(this.$store.getters.userDetails.shelter_status === 'submitted' || 
                  this.$store.getters.userDetails.shelter_status === 'verified')">
                <v-row align="center" v-if="$vuetify.breakpoint.xs">
                    <v-col class="text-caption" cols="12">
                        Complete your shelter profile
                    </v-col>
                    <v-col class="d-flex justify-end mt-n2" cols="12">
                        <v-btn depressed small color="error" :to="'/shelter-questionnaire'">Complete Now</v-btn>
                    </v-col>
                </v-row>
                <v-row align="center" v-else>
                    <v-col class="grow text-caption">
                        Complete your shelter profile
                    </v-col>
                    <v-col class="shrink">
                        <v-btn depressed small color="error" :to="'/shelter-questionnaire'">Complete Now</v-btn>
                    </v-col>
                </v-row>
            </v-alert>

            <div
                v-if="(this.$store.getters.userDetails.pets.length > 0 && this.$store.getters.userDetails.pets[0].name != '') || (this.$store.getters.userDetails.preferences && this.$store.getters.userDetails.preferences.active == 1)">

                <hooper :itemsToShow="1.15" :wheelControl="false" class="pl-4" v-if="$vuetify.breakpoint.xs"
                    :key="carouselKey">

                    <slide class="px-2 pt-8 pb-4" v-for="(item, index) in this.$store.getters.userDetails.pets"
                        :key="item.xid" :index="index">
                        <my-pet-card :petId="item.xid" @force-hooper-rerender="forceHooperRender"></my-pet-card>
                    </slide>

                    <slide class="px-2 pt-8 pb-4" v-if="this.$store.getters.userDetails.preferences">
                        <my-preference-card :preferences="this.$store.getters.userDetails.preferences"></my-preference-card>
                    </slide>

                    <slide class="px-2 pt-8 pb-4">
                        <v-card class="rounded-lg mild-shadow mx-3 mb-6">
                            <v-card-text class="pb-8">
                                <div v-if="!(this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                                <div class="text-center pt-12 pb-4">
                                    <v-btn fab rounded outlined
                                        style="border: 2px dashed #dddddd; height:90px;width:90px; border-radius:45px;"
                                        color="primary" :to="'/newpet'">
                                        <v-icon size="48" color="primary">mdi-plus</v-icon>
                                    </v-btn>
                                </div>
                                <div class="text-center">
                                    Add another pet
                                </div>
                                </div>

                                <div v-if="this.$store.getters.location != 'IN' && (this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                                <div class="text-center pt-16 pb-4" >
                                    <v-btn fab rounded outlined
                                        style="border: 2px dashed #dddddd; height:90px;width:90px; border-radius:45px;"
                                        color="primary" :to="'/search/available'">
                                        <v-icon size="48" color="primary">$vuetify.icons.search-icon</v-icon>
                                    </v-btn>
                                </div>
                                <div class="text-center">
                                    Find a pet to adopt/buy
                                </div>
                                </div>
                            </v-card-text>

                        </v-card>
                    </slide>

                    <hooper-pagination slot="hooper-addons"></hooper-pagination>

                </hooper>

                <v-row v-else class="pt-12">
                    <v-col cols="6" md="4" xl="3" v-for="(item) in this.$store.getters.userDetails.pets" :key="item.xid">
                        <my-pet-card :petId="item.xid"></my-pet-card>
                    </v-col>
                    <v-col cols="6" md="4" xl="3" v-if="this.$store.getters.userDetails.preferences">
                        <my-preference-card :preferences="this.$store.getters.userDetails.preferences"></my-preference-card>
                    </v-col>
                    <v-col cols="6" md="4" xl="3">
                        <v-card class="rounded-lg mild-shadow mx-3 mb-6">
                            <v-card-text class="pb-8">
                                <div v-if="!(this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                                <div class="text-center pt-12 pb-4">
                                    <v-btn fab rounded outlined
                                        style="border: 2px dashed #dddddd; height:90px;width:90px; border-radius:45px;"
                                        color="primary" :to="'/newpet'">
                                        <v-icon size="48" color="primary">mdi-plus</v-icon>
                                    </v-btn>
                                </div>
                                <div class="text-center">
                                    Add another pet
                                </div>
                                </div>

                                <div v-if="this.$store.getters.location != 'IN' && (this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                                <div class="text-center pt-12 pb-4">
                                    <v-btn fab rounded outlined
                                        style="border: 2px dashed #dddddd; height:90px;width:90px; border-radius:45px;"
                                        color="primary" :to="'/search/available'">
                                        <v-icon size="48" color="primary">$vuetify.icons.search-icon</v-icon>
                                    </v-btn>
                                </div>
                                <div class="text-center">
                                    Find a pet to adopt/buy
                                </div>
                                </div>
                            </v-card-text>

                        </v-card>
                    </v-col>
                </v-row>

            </div>

            <div v-else>

                <div v-if="!(this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                <div class="text-center pt-12 pb-4">
                    <v-btn fab rounded outlined
                        style="border: 2px dashed #dddddd; height:90px;width:90px; border-radius:45px;" color="primary"
                        :to="'/newpet'">
                        <v-icon size="48" color="primary">mdi-plus</v-icon>
                    </v-btn>
                </div>
                <div class="text-center">
                    Add Your Pet
                </div>
                </div>

                <div v-if="this.$store.getters.location != 'IN' && (this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                <div class="text-center pt-16 pb-4">
                    <v-btn fab rounded outlined
                        style="border: 2px dashed #dddddd; height:90px;width:90px; border-radius:45px;" color="primary"
                        :to="'/search/available'">
                        <v-icon size="48" color="primary">$vuetify.icons.search-icon</v-icon>
                    </v-btn>
                </div>
                <div class="text-center">
                    Find a pet to adopt/buy
                </div>
                </div>

            </div>


            <div>

                <v-alert  text  type="error" class="text-caption ma-2 mt-8" icon="mdi-shield-alert">
                    <p><b>Be Alert: Beware of scammers. </b></p>
                
                <p>Please avoid making any advance payments to other members without physically verifying the pet yourself. As Petmeetly does not act as an intermediary in financial transactions between members, we are unable to assist you in the event of financial loss due to scams.</p>

                <p>Please refrain from accepting advance payments through services like Zelle, Venmo, etc., even if it seems like a quicker way to close the deal. For tips on protecting yourself from scammers, you can refer to this article: <a href="https://www.aura.com/learn/zelle-scams">zelle-scams</a> (Note: We are not affiliated with aura).</p>
         
                <p>Report suspicious activity immediately to <u>support@petmeetly.com</u>.
                Keep our community safe!</p>
            </v-alert>

   </div>



        </div>

        </div>

        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>


        <v-dialog v-model="locationDialog" persistent max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Location Saved</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn icon @click="locationDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-card-text>

                    <div>Your location has been set to <strong class="primary--text">{{ location ? location.description :
                        '' }}</strong>. If you ever need to change it, just head over to the Account screen.</div>

                </v-card-text>

                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="closeLocationDialog">OK</v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>


    </v-container>
</template>

<script>
import {
    Hooper,
    Slide,
    Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';
import MyPetCard from './components/MyPetCard.vue'
import MyPreferenceCard from './components/MyPreferenceCard.vue'
import gmapsInit from '../plugins/gmaps';

export default {
    name: 'Home',

    async mounted() 
    {
        const google = await gmapsInit();
        //this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        this.service = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();

        //console.log('Token');
        //console.log(this.sessionToken);

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        }

        if(this.$store.getters.userDetails.latitude != null && (this.$store.getters.userDetails.category == null || this.$store.getters.userDetails.category == ""))
        {
            this.$store.dispatch('showCategoryPop', true)
        }


    },


    data() {
        return {
            carouselKey: 0,
            location: {
                description: '',
                placeId: '',
                state: '',
                country: '',
                zip: '',
                locality: '',
            },
            searchResults: [],
            service: null,
            geocoder: null,
            sessionToken: null,
            search: null,
            cordinates: null,
            locationPicked: false,
            locationDialog: false,

            gettingLocation: false,
            dataLoading: false,

            snackbar: false,
            snackbar_text: '',

            
            
        };
    },
    components: {
        Hooper,
        Slide,
        HooperPagination,
        MyPetCard,
        MyPreferenceCard
    },

    watch: {



        search(val) {
            if (val != '' && val !== null) {
                if (val.length > 3) {
                    this.service.getPlacePredictions({
                        input: val,
                        //sessionToken: this.sessionToken,
                        types: ['geocode']
                    }, this.displaySuggestions)
                }
            }
        }

    },

    methods: {
        forceHooperRender() {
            // Force the  component to re-render
            this.carouselKey += 1;
        },

        closeLocationDialog()
        {
            this.locationDialog = false; 
            this.$store.dispatch('showCategoryPop', true)
        },

        clearLocation() {

            //console.log('clear location');
            this.location = {
                description: '',
                placeId: '',
                state: '',
                country: '',
                zip: '',
                locality: '',
            };
            this.searchResults = [];
            this.locationPicked = false;

            //this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        },

        /* MapsInit() {
        console.log('Initializing map');
        this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        this.service = new window.google.maps.places.AutocompleteService();
        this.geocoder = new window.google.maps.Geocoder();
        }, */
        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = []
                return
            }
            this.searchResults = predictions.map(prediction => ({
                description: prediction.description,
                placeId: prediction.place_id
            }))
        },

        getPlaceDetails() {
            if (this.location && this.location.placeId != '') {
                //console.log(this.location.placeId);
                this.geocoder.geocode({
                    placeId: this.location.placeId
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.cordinates = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        };

                        this.locationPicked = true;
                        //console.log(this.cordinates);
                        //this.closeFieldDialog();

                        this.setUserLocation();

                    }
                });
            }
        },

        async getCurrentLocation() {
            this.gettingLocation = true;
            try {

                let location = await this.getLocation();

                //console.log(location);
                this.cordinates = {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude
                };

                this.geocoder.geocode({
                    location: this.cordinates
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        //console.log(results[0]);
                        this.gettingLocation = false;

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.location.description = results[0].formatted_address;
                        this.location.placeId = results[0].place_id;

                        this.searchResults = [{
                            description: results[0].formatted_address,
                            placeId: results[0].place_id
                        }];

                        this.locationPicked = true;

                        this.setUserLocation();

                        //console.log(this.searchResults);

                        //this.closeFieldDialog();

                    }
                });

            } catch (e) {
                this.gettingLocation = false;
                this.errorStr = e.message;
            }

        },

        async getLocation() {

            return new Promise((resolve, reject) => {

                if (!("geolocation" in navigator)) {
                    reject(new Error('Geolocation is not available.'));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                    resolve(pos);
                }, err => {
                    reject(err);
                });

            });
        },

        setUserLocation()
        {

          this.submitting = true;

                let formData = new FormData();


                formData.append('location', JSON.stringify(this.location));
                formData.append('cordinates', JSON.stringify(this.cordinates));



                this.$store.dispatch('setUserLocation', formData)
                    .then((resp) => {

                        this.submitting = false;
                        this.locationDialog = true;

                    })
                    .catch(err => {

                        this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

        },

        
    }

}
</script>

<style scoped>.hooper-pagination {
    bottom: 10px;
}</style>
