<template>
<div class="chats">

    <div v-if="loading">

        <v-row no-gutters>
                    <v-col cols="12" justify="center" align="center" class="my-12">
                        <img :src="$loaderImageUrl" height="100" alt="Loading..." />
                    </v-col>

                </v-row>

        <!-- <v-row justify="center" no-gutters>
            <v-col cols="12" sm="6" md="4" lg="3" class="text-left">
                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="6" md="4" lg="3" class="text-right">
                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </v-col>
        </v-row> -->
    </div>

    <div v-else>

      <v-app-bar app flat dark color="primary" max-height="56" >
          <v-btn icon dark :to="'/messages'">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <div>
              <v-avatar size="38"  v-if="otherParty.pet"
                        @click.stop="toggleDetails">
                <v-img v-if="otherParty.pet.avatar"
                       :src="otherParty.pet.avatar.photo"></v-img>
                <v-icon v-else>{{ otherParty.pet.pet_type ?
                    '$vuetify.icons.' + otherParty.pet.pet_type.icon : 'mdi-paw' }}</v-icon>
              </v-avatar>
              <v-avatar size="38"  v-else @click.stop="toggleDetails">
                <v-img v-if="otherParty.user && otherParty.user.photo"
                       :src="otherParty.user.photo"></v-img>
                <v-icon v-else size="24">$vuetify.icons.account-icon</v-icon>
              </v-avatar>
            </div>

            <v-app-bar-title class="text-subtitle-2 font-weight-medium pl-2 mt-1" @click="toggleDetails">
                {{ otherParty && otherParty.pet ? otherParty.pet.name : otherParty.user.name }}
                <div class="text-caption" v-if="otherParty && otherParty.pet">c/o {{ otherParty.pet.user.name }}</div>
            </v-app-bar-title>

            <v-spacer></v-spacer>

            <v-btn icon v-if="!showDetails"
                   @click="showDetails = true">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

            <v-btn icon @click="showDetails = false"
                   v-if="showDetails">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-app-bar>


        <div no-gutters class="soft-shadow" style="display: flex; flex-direction: column; max-height: 70%; width: 100%; position: absolute; top: 57px; z-index: 1; left:0; right:0; overflow: hidden;">

          <!-- Content section: This section will grow and shrink as needed and will scroll if content overflows -->
          <div style="flex-grow: 1; overflow-y: auto;">
              <v-expand-transition>
                  <v-card v-show="showDetails">
                      <v-card-text class="pa-0">
                          <div>
                              <pet-details :pet="otherParty.pet" v-if="otherParty.pet"></pet-details>
                              <user-details :user="otherParty.user" v-if="!otherParty.pet"></user-details>
                          </div>
                      </v-card-text>
                  </v-card>
              </v-expand-transition>
          </div>

          <!-- Bottom section: This section will not grow, ensuring the button stays at the bottom -->
          <div style="flex-shrink: 0;">
            <v-btn tile color="#ddd" block v-show="showDetails" @click="showDetails = false">
              <v-icon>mdi-chevron-up</v-icon> <small>HIDE DETAILS</small> <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </div>

        </div>

        <v-alert border="left" text color="error" class="ma-2"  v-if="otherParty.pet && !otherParty.pet.active" >
          <v-row align="center">
            <v-col class="grow"><h5 class="font-weight-bold red--text text--darken-1 pb-2">Not Available:</h5>
              <div class="text-caption">The pet owner has removed the post; the pet is no longer available.</div></v-col>
          </v-row>
        </v-alert>

        <v-alert v-else text dense border="left" type="error" class="text-caption ma-2" icon="mdi-shield-alert">
            <b>Be Alert:</b> Beware of scammers. Watch out for potential scams and never engage in financial
            transactions without verifying the other party. <a href="javascript:void(0);"
                                                             @click="reportSupportDialog = true"
                                                             class="red--text font-weight-bold text-decoration-underline">Report</a> suspicious activity immediately.
            Keep our community safe!
        </v-alert>

        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">

                <v-card-text ref='messageList' class="pt-4">
                    <div v-if="localConversation" :key="key">
                        <v-row v-for="(item,index) in this.$store.getters.getConversation(localConversation.xid).messages" :key="index" :justify="item.fuid == $store.getters.userDetails.xid?'end':'start'">
                            <v-col cols="10">
                                <div class="text-caption pa-1" v-show="item.fuid != $store.getters.userDetails.xid">{{item.created_at||new Date() | moment("D MMM, h:mm a")}}</div>

                                <v-sheet v-if="item.message == 'LOCKED'" color="#edf0f5"  class="rounded-tl-0 rounded-xl pa-4 justify-center align-center d-flex flex-column">
                                    <v-skeleton-loader
                                        class="mx-auto mb-2" boilerplate
                                        width="100%"
                                        type="sentences"
                                    ></v-skeleton-loader>
                                    <v-btn  rounded small depressed color="success" @click="triggerViewMessage(item.xid)">
                                      <v-icon left>mdi-email-open</v-icon> View Message
                                    </v-btn>
                                </v-sheet>

                                <v-sheet v-else :color="item.fuid == $store.getters.userDetails.xid ? 'primary' : '#edf0f5'"
                                         :dark="item.fuid == $store.getters.userDetails.xid"
                                         :class="item.fuid == $store.getters.userDetails.xid ? 'rounded-br-0 rounded-xl pa-4' : 'rounded-tl-0 rounded-xl pa-4'">
                                  <span class="text-pre-wrap" v-if="item.message_type == 'text'">{{ item.message }}</span>
                                  <v-img v-else class="ml-auto rounded-xl" max-height="200px" :src="item.photo.photo" cover aspect-ratio="1" @click="showPictureModal(item.photo.photo)"></v-img>
                                  <v-alert dark @click="showPremium"
                                           v-if="item.message.indexOf('##***Contact Details***##') !== -1 && item.fuid != $store.getters.userDetails.xid"
                                           color="#2196f3" dense icon="mdi-lock-outline"
                                           class="text-caption mt-2 mb-n2 mx-n2 rounded-xl">Upgrade to Premium to reveal contact details.</v-alert>
                                  <v-alert @click="showPremium"
                                           v-if="item.message.indexOf('##***Contact Details***##') !== -1 && item.fuid == $store.getters.userDetails.xid"
                                           color="purple darken-4" dense icon="mdi-lock-outline"
                                           class="text-caption mt-2 mb-n2 mx-n2 rounded-xl">Upgrade to Premium Membership to share contact details.</v-alert>
                                  <v-alert @click="showPremium"
                                           v-if="!item.verified" prominent
                                           dense  icon="mdi-lock"  color="purple darken-4"
                                           class="text-caption mt-2 mb-n2 mx-n2 rounded rounded-l-xl">{{ otherParty.pet ? otherParty.pet.name : otherParty.user.name }} can't see this message. Upgrade to Premium Membership for seamless conversations.
                                    <div class="text-right"><v-btn text small>UPGRADE NOW</v-btn></div></v-alert>
                                </v-sheet>

                                <div class="text-caption text-right pa-1" v-show="item.fuid == $store.getters.userDetails.xid">
                                  {{ item.created_at || new Date() | moment("D MMM, h:mm a") }}

                                  <!-- <span class="pl-2 orange--text" v-if="!item.verified && !item.read"
                                      @click="legendDialog = true">
                                      <v-icon small color="orange">
                                          mdi-timer-sand
                                      </v-icon>
                                      Pending <span class="pl-1"><a>why?</a></span>
                                  </span> -->
                                  <span class="pl-2" v-if="item.verified && !item.read" @click="legendDialog = true">
                                      <v-icon small color="green">
                                          mdi-check
                                      </v-icon>
                                  </span>
                                  <span class="pl-2" v-if="item.read" @click="legendDialog = true">
                                      <v-icon small color="blue">
                                          mdi-check-all
                                      </v-icon>
                                  </span>

                                </div>
                            </v-col>
                        </v-row>
                    </div>

                </v-card-text>

            </v-col>
        </v-row>

        <v-footer color="#ffffff" app padless class="justify-center">

            <v-row justify="center" no-gutters>
                <v-col cols="12" sm="6" md="4" lg="3" class="justify-center pa-2">
                  <div class="text-center"
                       v-if="otherParty.user && otherParty.user.banned">
                    <v-chip color="error">Account has been banned</v-chip>
                  </div>

                  <div class="text-center"
                       v-else-if="otherParty.user && otherParty.user.deleted">
                    <v-chip>Account has been closed</v-chip>
                  </div>

                  <div v-else>
                    <div class="mb-2" v-if="!localConversation">
                      <div v-if="otherParty.pet">
                        <v-chip color="primary" outlined  class="mr-2 mb-2" @click="quickMessage('i_am_interested')">I'm interested</v-chip>
                        <v-chip color="primary" outlined class="mr-2 mb-2" @click="quickMessage('is_it_available')">Is {{otherParty.pet.gender == 'M'? 'he' : 'she'}} available?</v-chip>
                      </div>
                      <div v-else>
                        <v-chip color="primary" outlined  class="mr-2 mb-2" @click="quickMessage('are_you_interested')">Are you interested?</v-chip>
                      </div>

                    </div>
                    <v-form ref="form" @submit.prevent="sendMessage" v-if="!hideSendMessage() && !blockedByUsersMessageTxt && !blockedUsersMessageTxt">
                      <v-textarea rows="1" v-model="message" auto-grow  solo placeholder="Send message..."
                                  type="text" color="primary" hide-details flat background-color="#edf0f5"
                                  class="rounded-xl chat-compose-input" append-icon="mdi-send"
                                  @click:append="sendMessage"  :append-outer-icon="appendOuter" @click:append-outer="launchAttachImage"
                                  :disabled="sending" :loading="sending">

                      </v-textarea>

                      <input type="file" ref="messageImage" name="messageImage" @change="loadImage($event)" style="display:none" accept="image/png, image/jpg, image/jpeg">

                      <v-dialog v-model="cropDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                        <v-card color="black">
                          <v-toolbar flat dark color="black">
                            <v-btn icon @click="cropDialog = false" color="grey">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn icon color="white" @click="holdPictureMessage" :loading="psubmitting" :disabled="psubmitting">
                              <v-icon>mdi-check</v-icon>
                            </v-btn>

                          </v-toolbar>
                          <v-card-text class="pa-0">
                            <cropper ref="cropper" :src="pictureFile" :canvas="{
                                                  height: 600,
                                                  width: 800
                                                }" :stencil-props="{
                                                    aspectRatio: 8/6,
                                                    movable: true,
                                                    scalable: true,
                                                }" image-restriction="stencil" />

                          </v-card-text>
                        </v-card>
                      </v-dialog>

                    </v-form>

                    <v-dialog v-model="pictureDialog" max-width="400">
                      <v-card>
                        <v-toolbar flat>
                          <v-toolbar-title class="text-subtitle-1">Add to Profile?</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="pictureDialog = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>

                        <v-card-text>
                          <p>
                            Would you like to attach this picture to your pet's profile?
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn text color="grey" @click="sendPictureMessage(0)" :loading="msubmitting">No</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="sendPictureMessage(1)" :loading="psubmitting">Yes</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <div class="text-center" v-if="blockedUsersMessageTxt">
                      <v-chip class="ma-2">{{ blockedUsersMessageTxt }}</v-chip>
                      <v-btn @click="unblockDialog=true;" outlined  rounded small color="success" style="background-color: #eef9e9;"><v-icon small >mdi-block-helper</v-icon>&nbsp;Unblock</v-btn>
                    </div>
                    <div class="text-center" v-else-if="blockedByUsersMessageTxt">
                      <v-chip>{{ blockedByUsersMessageTxt }}</v-chip>
                    </div>

                  </div>

                </v-col>
            </v-row>

        </v-footer>

    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-dialog v-model="legendDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Message Status</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="legendDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">
                <v-row>
                    <v-col cols="1" class="pb-0">
                        <v-icon color="orange">mdi-timer-sand</v-icon>
                    </v-col>
                    <v-col cols="11" class="pl-4 pb-0"><div class="orange--text">Pending</div> <span class="text-caption">Message queued for manual review.</span></v-col>
                </v-row>
                <v-row v-if="!this.$store.getters.isPremium">
                    <v-col>
                        <v-alert text  dense class="text-caption text-center mb-0 pa-4">Premium Membership ensures your messages are prioritized and delivered instantly.
                            <div class="text-center mt-3 mb-2">
                                <v-btn  small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO PREMIUM</v-btn>
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="1" class="pb-0">
                        <v-icon color="green">mdi-check</v-icon>
                    </v-col>
                    <v-col cols="11" class="pl-4 pb-0">
                        <div class="green--text">Delivered</div> <span class="text-caption">Message has been delivered but not yet read.</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="pb-0">
                        <v-icon color="blue">mdi-check-all</v-icon>
                    </v-col>
                    <v-col cols="11" class="pl-4 pb-0">
                        <div class="blue--text">Read</div> <span class="text-caption">Message has been read by the other party.</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="legendDialog=false">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="msgLimitDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">You hit a limit!</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="msgLimitDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">
                <div class="text-center pb-6">
                    <v-icon color="grey lighten-2" size="80">mdi-chat-alert-outline</v-icon>
                </div>
                <div class="text-center">
                    <p class="text-subtitle-2 font-weight-bold">You have reached your daily quota of 3 messages. </p>
                    <p class="text-caption">Please wait till tomorrow to send more messages or join our <a
                        @click="showPremium">Premium Membership</a> program for unlimited messages.</p>
                    <div class="text-center mt-3 mb-2">
                        <v-btn small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                        PREMIUM</v-btn>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="msgLimitDialog = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="noContactDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Cannot Share Contact</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="noContactDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">
                <div class="text-center pb-6">
                    <v-icon color="grey lighten-2" size="80">mdi-phone-off-outline</v-icon>
                </div>

                <div class="text-center">
                    <p>Contact details can only be shared if you're a premium member or if you're communicating with
                    one.</p>

                    <div class="text-center mt-8 mb-2">
                        <v-btn small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                        PREMIUM</v-btn>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="noContactDialog = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="premiumReqDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="premiumReqDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <div class="text-center">
                    <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37" large>mdi-crown</v-icon>
                </div>

                <div class="text-center pt-8">
                    <p>For full messaging access, consider our Premium Membership.</p>
                    <p>Upgrading to our Premium Membership allows you to freely send and receive messages with any member on Petmeetly</p>
                    <div class="text-center my-8">
                        <v-btn  class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO PREMIUM</v-btn>
                    </div>
                    <p class="text-caption">Note: You can communicate with any <strong class="black--text">Premium Member</strong> without upgrading.</p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="premiumReqDialog = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="noContactDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Cannot Share Contact</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="noContactDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">

                <div class="text-center pb-6">
                    <v-icon color="grey lighten-2" size="80">mdi-phone-off-outline</v-icon>
                </div>

                <div class="text-center">
                    <p>Contact details can only be shared if you're a premium member or if you're communicating with one.</p>
                    
                    <div class="text-center mt-8 mb-2">
                        <v-btn  small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO PREMIUM</v-btn>
                    </div>
                </div>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="noContactDialog=false">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="viewMessageDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="viewMessageDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <div class="text-center">
                    <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37" large>mdi-crown</v-icon>
                </div>

                <div class="text-center pt-8">
                    <p>For full messaging access, consider our Premium Membership.</p>
                    <p>Upgrading to our Premium Membership allows you to freely send and receive messages with any member on Petmeetly</p>

                    <div class="text-center my-8">
                      <v-btn  class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO PREMIUM</v-btn>
                    </div>

                    <v-divider class="mb-4"></v-divider>
                    <div>
                        <p class="text-caption">Or you may unlock this message using message credits</p>
                    </div>
                    <div>
                       <v-btn small color="primary" :loading="unlocking" :disabled="unlocking || this.$store.getters.userDetails.mcredits<1" @click="unlockConfirm = true" >UNLOCK THIS MESSAGE</v-btn>
                    </div>
                    <p class="text-caption pt-2">You have <strong class="black--text">{{ this.$store.getters.userDetails.mcredits }}</strong> message credit<span v-show="this.$store.getters.userDetails.mcredits != 1">s</span>. <span style="cursor:pointer" class="info--text" @click="$store.dispatch('showMessageCreditPop', true);">Get Credits.</span></p>
                </div>


            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="viewMessageDialog = false">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="unlockConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p>Are you sure you want to use a message credit to Unlock this message</p>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="grey" @click="unlockConfirm = false">No</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="unlockMessage" :loading="unlocking" :disabled="unlocking">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="reportSupportDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Report suspicious activity</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="reportSupportDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">
                <p>
                    Please forward all details and any reasons for suspicion of fraudulent activity to our support team
                    at <strong>support@petmeetly.com</strong>
                </p>
                <p>
                    Our team will thoroughly investigate your report and take the appropriate action as needed.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="reportSupportDialog = false">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showPicture" fullscreen scrollable>
        <v-card>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn icon @click="showPicture = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
               <v-img :src="pictureUrl" contain ></v-img>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import PetDetails from "./components/ChatPetDetail.vue";
import UserDetails from "./components/ChatUserDetail.vue";

export default {
    name: 'Chats',
    components: {
      UserDetails,
      PetDetails
    },
    props: ['id'],

    mounted() {
        this.getConversation();
    },

    updated() {
        setTimeout(() => {
            this.scrollToEnd();

        }, 300);
    },


    computed: {
        blockedByUsers() { /* List of users blocked the current user */
          return this.$store.getters.userDetails.meBlocked ? this.$store.getters.userDetails.meBlocked.map(o => {
            return o.bbid;
          }) : [];
        },

        blockedUsers() { /* List of users blocked by the current user */
          return this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
            return o.buid;
          }) : [];
        }
    },
    data() {
        return {
            showDetails: false,
            pet: null,
            user: null,
            blockedUsersMessageTxt: '',
            blockedByUsersMessageTxt: '',
            showPicture : false,
            pictureUrl: '',
            ownerPetId: '',
            appendOuter: '',
            msubmitting: false,
            psubmitting: false,
            pictureDialog: false,
            pictureFile: false,
            uploadedFile: null,
            unblockDialog: false,
            unblockUserXid: '',
            cropDialog: false,
            reportSupportDialog: false,
            premiumReqDialog: false,
            viewMessageDialog: false,
            unlocking: false,
            unlockConfirm: false,

            legendDialog: false,
            msgLimitDialog: false,
            noContactDialog: false,
            message: '',
            loading: false,
            sending: false,
            snackbar: false,
            snackbar_text: '',
            messages: [],
            localConversation: null,
            key: '',
            otherParty: {
                pet: {
                    user: {}
                },
                user: {}
            }

        }
    },

    methods: {

        getConversation() {

            this.loading = true;

            if (this.$store.getters.userDetails.name == '') {
                this.$store.dispatch('profile').then(() => {
                    this.getConversationData();   
                });
            } else {
                this.getConversationData();  
            }

        },

        getConversationData() {

            this.$store.dispatch('getConversationById', this.id).then((resp) => {
                if(resp.data.xid)
                {
                    this.localConversation = resp.data;
                    this.otherParty = this.localConversation.parties.find(
                        conv => conv.uid !== this.$store.getters.userDetails.xid
                    );

                    if (this.localConversation && this.localConversation.parties) {
                        if (this.localConversation.parties[0].user.xid == this.$store.getters.userDetails.xid && this.localConversation.parties[0].pet) {
                            this.appendOuter = 'mdi-image-plus';
                            this.ownerPetId = this.localConversation.parties[0].pid;

                            this.user = this.localConversation.parties[1].user;
                            this.pet = this.localConversation.parties[1].pet;

                        } else if (this.localConversation.parties[1].user.xid == this.$store.getters.userDetails.xid && this.localConversation.parties[1].pet) {
                            this.appendOuter = 'mdi-image-plus';
                            this.ownerPetId = this.localConversation.parties[1].pid;

                            this.user = this.localConversation.parties[0].user;
                            this.pet = this.localConversation.parties[0].pet;
                        }
                    }

                    /* List of users blocked by the current user */
                    let mbu = this.$store.getters.userDetails.myBlocked.map(o => {
                        return o.buid;
                    });

                    this.blockedUsersMessage(mbu);
                    this.blockedByUsersMessage();

                    this.getMessages();
                }
                else
                {
                    this.$router.replace('/messages');
                }
            });
        },

        getMessages() {

            this.$store.dispatch('getMessages', this.id).then((resp) => {
                    setTimeout(() => {
                        this.key = (new Date()).getTime();
                        this.loading = false;
                        this.scrollToEnd();
                    }, 300);
                })
                .catch(err => {
                    //console.log(err);
                    this.loading = false;
                });

        },
        sendMessage() {

            if (this.message == '' || this.message.length < 6) {

                this.snackbar_text = "Your message is too short";
                this.snackbar = true;

            } else {

                if (this.$store.getters.userDetails.is_premium == 1 || this.otherParty.is_premium == 1) {
                    //this.message = this.message
                } else {
                    /* var emailExp = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/img;
                    var phoneExp = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img;

                    var msg = this.message.replace(emailExp, '##***Contact Details***##');
                    msg = msg.replace(phoneExp, '##***Contact Details***##');

                    if (msg.indexOf('##***Contact Details***##') !== -1) {
                        this.noContactDialog = true;
                        return;

                    } */

                }

                if (this.sending == false) {
                    this.sending = true;

                    /* this.$store.dispatch('checkMessageLimit')
                        .then((resp) => {
                            var msg_limit = resp.limit
                            if (msg_limit <= 0) {
                                this.msgLimitDialog = true
                                this.sending = false;
                                return;

                            } else { */

                                let fuid = this.$store.getters.userDetails.xid;
                                let tuid = this.otherParty.uid;
                                let cid = this.localConversation.xid;
                                let message = this.message;

                                this.$store.dispatch('sendMessage', {
                                        fuid,
                                        tuid,
                                        cid,
                                        message
                                    })
                                    .then((resp) => {
                                        this.sending = false;
                                        this.message = '';
                                        setTimeout(() => {
                                            this.scrollToEnd();
                                        }, 300);
                                    })
                                    .catch(err => {
                                        //console.log(err);
                                        this.sending = false;
                                    });

                        /*     }
                        })
                        .catch(err => {
                            this.sending = false;
                        }); */

                }

            }

        },
        showSubscription() {

        },
        scrollToEnd() {

            //window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
            var container = this.$refs.messageList;
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            if (container)
            {
                container.scrollTop = container.scrollHeight;
            }
                

            

        },

        goToPet(xid) {
            this.$router.push({
                name: 'Pet',
                params: {
                    id: xid
                }
            })
        },

        showPremium() {
            this.$store.dispatch('showPremiumPop', true);
        },
        toggleDetails()
        {
            this.showDetails = !this.showDetails
        },
        hideSendMessage() {
            if (this.otherParty.user) {
                return this.blockedByUsers.includes(this.otherParty.user.xid) || this.blockedUsers.includes(this.otherParty.user.xid);
            }
        },
        blockedUsersMessage(mbu)
        {
            this.blockedUsersMessageTxt = mbu.includes(this.otherParty.user.xid) ? 'You have blocked ' + this.otherParty.user.name : '';
            this.unblockUserXid = this.otherParty.user.xid;
        },
        blockedByUsersMessage() {
            this.blockedByUsersMessageTxt = this.blockedByUsers.includes(this.otherParty.user.xid) ? 'You have been blocked by the user' : '';
        },
      showPictureModal(picUrl) {
        this.pictureUrl = picUrl;
        this.showPicture = true;
      },
      launchAttachImage() {
        this.$refs.messageImage.click();
      },
      loadImage(event) {
        var input = event.target;

        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.pictureFile = e.target.result;
            this.cropDialog = true;
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      holdPictureMessage()
      {
        this.psubmitting = true;
        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob((blob) => {
            // Convert blob to File object
            this.uploadedFile = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });
            this.psubmitting = false;
            this.cropDialog = false;
            this.pictureDialog = true;


          }, "image/jpeg");
        }
      },

      sendPictureMessage(publicUpload) {
        if (this.psubmitting == false && this.msubmitting == false) {
          if(publicUpload) {
            this.psubmitting = true;
          } else {
            this.msubmitting = true;
          }

          let fuid = this.$store.getters.userDetails.xid;
          let tuid = this.otherParty.user.xid;

          let formData = new FormData();

          formData.append('fuid', fuid);
          formData.append('tuid', tuid);
          formData.append('photo', this.uploadedFile);
          formData.append('pet_id', this.ownerPetId);
          formData.append('public', publicUpload);

          if (this.localConversation) {
            //Add message to exisitng conversation

            let cid = this.localConversation.xid;

            formData.append('cid', this.localConversation.xid);

            this.$store.dispatch('sendPictureMessage', formData)
                .then((resp) => {
                  if(publicUpload) {
                    this.psubmitting = false;
                  } else {
                    this.msubmitting = false;
                  }
                  this.message = '';
                  this.pictureDialog = false;
                  setTimeout(() => {
                    this.scrollToEnd();
                  }, 300);
                })
                .catch(err => {
                  this.psubmitting = false;
                });
          }
        }
      },
      unlockMessage()
      {
        let mxid = this.message_xid
        let cxid = this.localConversation.xid;
        this.unlocking = true;
        this.unlockConfirm = false;

        this.$store.dispatch('unlockMessage', {
          mxid,cxid
        })
            .then((resp) => {
              this.$store.dispatch('profile');
              this.getMessages();
              this.snackbar_text = resp.data.message;
              this.snackbar = true;
              this.viewMessageDialog = false;
              this.unlocking = false;
            })
            .catch(err => {
              this.unlocking = false;
              this.snackbar_text = "Error unlocking";
              this.snackbar = true;
            });

      },
      quickMessage(msg)
      {
        this.sending = true;

        let fuid = this.$store.getters.userDetails.xid;
        let tuid = this.otherParty.user.xid;
        let fpid = this.$store.getters.myPetID;
        let tpid = this.otherParty.pet ? this.otherParty.pet.xid : null;
        let message = msg;

        this.$store.dispatch('newConversation', {
          fuid,
          tuid,
          fpid,
          tpid,
          message
        })
            .then((resp) => {
              this.localConversation = resp;
              this.sending = false;
              this.message = '';
              this.scrollToEnd();
              //this.$store.dispatch('setMessageLimit', msg_limit - 1);
            })
            .catch(err => {
              //console.log(err);
              this.sending = false;
            });
      },
      triggerViewMessage(mxid)
      {
        this.message_xid = mxid
        this.viewMessageDialog = true

      },
    }
}
</script>
