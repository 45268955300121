<template>
  <v-card-text scrollable>

    <v-alert border="left" text color="error"  v-if="!pet.active" >
              <v-row align="center">
                <v-col class="grow"><h5 class="font-weight-bold red--text text--darken-1 pb-2">Not Available:</h5>
                  <div class="text-caption">The pet owner has removed the post; the pet is no longer available.</div></v-col>
              </v-row>
            </v-alert>


            <div class="d-flex flex-row align-items-center">
  <div class="flex-shrink-1">
    <v-avatar size="80" rounded class="rounded-lg">
      <v-img v-if="pet.avatar" :src="pet.avatar.photo"></v-img>
      <v-icon v-else color="primary">{{ pet.pet_type ? '$vuetify.icons.' + pet.pet_type.icon : 'mdi-paw' }} </v-icon>
    </v-avatar>
  </div>

  <div class="flex-grow-1 pl-4">
    <h3>
      {{ pet.name }}
      <v-icon v-show="pet.is_premium" size="20" color="#d4af37" class="px-1">mdi-crown</v-icon>
      <v-icon v-show="pet.video_verified" size="20" color="success">mdi-check-decagram</v-icon>
    </h3>
    <div class="text-truncate text-caption">{{ pet.breed }}</div>
    <div class="text-truncate text-caption">
      {{ pet.age ? pet.age.long : '' }} old, {{ pet.gender == 'M' ? 'Male' : 'Female' }}
    </div>
    <div class="text-truncate text-caption">
      <v-icon x-small>mdi-map-marker-outline</v-icon> {{ location }}
    </div>
  </div>

  <!-- Rightmost button -->
  <div class="ml-auto">
    <v-btn icon @click="markFavourite" :loading="favLoading" v-show="pet.user.xid != $store.getters.userDetails.xid">
      <v-icon color="red" v-if="pet.is_favorite">
        mdi-heart
      </v-icon>
      <v-icon v-else color="grey lighten-1">
        mdi-heart-outline
      </v-icon>
    </v-btn>
  </div>
</div>

    



    <v-row no-gutters class="py-2">
      <v-col>
        <v-sheet outlined rounded class="pa-1 py-2 mx-n1" color="#f8f8f8" >

          <v-chip label  small  color="#f8f8f8" v-show="pet.price > 0">
    <v-icon left small color="primary">mdi-currency-usd</v-icon>
    Price: {{Number(pet.price).toLocaleString()}} {{ pet.price_ccy == null ? '$ USD' : pet.price_ccy }}</v-chip>

<v-chip label  small  color="#f8f8f8" v-show="pet.adoption_fees > 0">
    <v-icon left small color="primary">mdi-currency-usd</v-icon>
    Adoption Fee: {{Number(pet.adoption_fees).toLocaleString()}} {{ pet.adoption_fees_ccy == null ? '$ USD' : pet.adoption_fees_ccy }}</v-chip>

<v-chip label  small  color="#f8f8f8" v-show="pet.stud_fees > 0">
    <v-icon left small color="primary">mdi-currency-usd</v-icon>
    Stud Fee: {{Number(pet.stud_fees).toLocaleString()}} {{ pet.stud_fees_ccy == null ? '$ USD' : pet.stud_fees_ccy }}</v-chip>


<v-chip label  small  color="#f8f8f8">
    <v-icon left small color="primary">mdi-weight</v-icon>
{{pet.weight}} {{pet.weight_unit}}</v-chip>

<v-chip label v-if="pet.purebred" small  color="#f8f8f8">
    <v-icon left small color="primary">mdi-water</v-icon>
Purebred</v-chip>

<v-chip label v-if="pet.pedigree_certified && pet.pedigree_verified" small  color="#f8f8f8">
    <v-icon left small color="primary">mdi-seal-variant</v-icon>
Pedigree Certified</v-chip>

<v-chip label v-if="pet.dna_tested && pet.dna_verified" small color="#f8f8f8">
    <v-icon left small color="primary">mdi-dna</v-icon>
DNA Tested</v-chip>

<v-chip label v-if="pet.vaccinated" small color="#f8f8f8">
    <v-icon left small color="primary">mdi-needle</v-icon>
Vaccinated</v-chip>

<v-chip label v-if="pet.potty_trained" small color="#f8f8f8">
    <v-icon left small color="primary">mdi-toilet</v-icon>
Potty Trained</v-chip>

<v-chip label v-if="pet.neutered" small color="#f8f8f8">
    <v-icon left small color="primary">mdi-check</v-icon>
Neutered</v-chip>

<v-chip label v-if="pet.good_with_kids" small color="#f8f8f8">
    <v-icon left small color="success">mdi-check</v-icon>
Good with kids</v-chip>

<v-chip label v-if="pet.good_with_kids == 0" small color="#f8f8f8">
    <v-icon left small color="error">mdi-close</v-icon>
Not good with kids</v-chip>

<v-chip label v-if="pet.good_with_cats" small color="#f8f8f8">
    <v-icon left small color="success">mdi-check</v-icon>
Good with cats</v-chip>

<v-chip label v-if="pet.good_with_cats == 0" small color="#f8f8f8">
    <v-icon left small color="error">mdi-close</v-icon>
Not good with cats</v-chip>

<v-chip label v-if="pet.good_with_other_dogs" small color="#f8f8f8">
    <v-icon left small color="success">mdi-check</v-icon>
Good with dogs</v-chip>

<v-chip label v-if="pet.good_with_other_dogs == 0" small color="#f8f8f8">
    <v-icon left small color="error">mdi-close</v-icon>
Not good with dogs</v-chip>



</v-sheet>



      </v-col>
    </v-row>

    <v-row justify="center" no-gutters>
      <v-col cols="12" class="justify-center text-caption text-pre-wrap py-2">

        {{pet.description}}
                
                <v-divider class="my-4" style="border-color: #eee" v-if="pet.willingness_to_travel != null || pet.breeding_terms != null || pet.accommodation_responsibility != null || pet.preferred_payment_method != null"></v-divider>

                
                <div class="" v-if="pet.willingness_to_travel != null"><strong>Willingness to travel: </strong>
                    <br/><div class="pb-2">{{pet.willingness_to_travel}}</div></div>
                <div class="" v-if="pet.breeding_terms != null"><strong>Breeding Terms:  </strong>
                    <br/><div class="pb-2">{{pet.breeding_terms}}</div></div>
                <div class="" v-if="pet.accommodation_responsibility != null"><strong>Accommodation Responsibility:  </strong>
                    <br/><div class="pb-2">{{pet.accommodation_responsibility}}</div></div>
                <div class="" v-if="pet.preferred_payment_method != null"><strong>Preferred payment method:  </strong>
                    <br/><div class="pb-2">{{pet.preferred_payment_method}}</div></div>
               
            </v-col>
        </v-row>

        <div v-if="pet.purpose == 'lover' && (pet.extra_info_status == 'submitted' || pet.extra_info_status == 'verified')">
            
            <v-sheet  class="pa-4 my-4 mt-2 rounded-lg" style="background-color: #f2f2ff;">
                
            <div class="text-caption">Has the pet been bred before?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.previous_breeding == 1 ? "Yes" : "No" }}. {{ pet.breeding_count > 0 ? (pet.breeding_count > 1 ? pet.breeding_count + ' times' : 'One time') : '' }}</div>

            <div class="text-caption pt-2" v-if="pet.extra_info_verified">Does the pet have any known health issues?</div>
            <div class="text-caption black--text font-weight-bold" v-if="pet.extra_info_verified">{{ pet.health_issues }}</div>

            
            <div class="float-right mt-n4 primary--text text-caption"  style="cursor:pointer" v-if="!moreInfo" @click="moreInfo = !moreInfo">View More...</div>
            
            <v-expand-transition v-if="moreInfo">
                <div>

            <div class="text-caption pt-2" v-if="pet.extra_info_verified">Pet's personality, energy level, and behavior with people and other animals.</div>
            <div class="text-caption black--text font-weight-bold" v-if="pet.extra_info_verified">{{ pet.temperament }}</div>

            <div class="text-caption pt-2">Familiar with the breed standard and any genetic health issues associated with this breed?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.familiar_with_breed_standard == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Consulted with a veterinarian about breeding the pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.consulted_veterinarian == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Has the pet been tested for any breed-specific health conditions or genetic disorders?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.has_health_tests == 1 ? 'Yes' : 'No'}}. {{ pet.extra_info_verified ? pet.health_test_results : '' }}</div>

            <div class="text-caption pt-2">Willing to sign a breeding contract?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.willing_to_sign_contract == 1 ? 'Yes' : 'No' }}</div>

        </div>
        </v-expand-transition>    
        
        </v-sheet>
        </div>


        <div v-if="pet.extra_info_verified && (pet.purpose == 'adopter' || pet.purpose == 'buyer')">
            
            <v-sheet  class="pa-4 my-4 mt-2 rounded-lg" style="background-color: #f2f2ff;">
                
            <div class="text-caption">Is the pet spayed/neutered?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.is_pet_spayed_neutered == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption" v-if="!pet.is_pet_spayed_neutered">Willing to have the pet spayed/neutered before sale?</div>
            <div class="text-caption black--text font-weight-bold" v-if="!pet.is_pet_spayed_neutered">{{ pet.willing_to_spay_neuter == 1 ? "Yes" : "No" }}</div>
            

            <div class="text-caption pt-2">Does the pet have any known health issues?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.health_issues }}</div>

            
            <div class="float-right mt-n4 primary--text text-caption"  style="cursor:pointer" v-if="!moreInfo" @click="moreInfo = !moreInfo">View More...</div>
            
            <v-expand-transition v-if="moreInfo">
                <div>

            <div class="text-caption pt-2">Pet's current living environment</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.living_situation }}</div>

            <div class="text-caption pt-2">Type of food and how much exercise does it get daily?</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.diet_and_exercise}}</div>

            <div class="text-caption pt-2">Included with Sale/Adoption</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.included_items}}</div>

            <div class="text-caption pt-2">Willing to meet the buyer/adopter halfway</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.willing_to_meet == 1 ? 'Yes' : 'No'}}</div>

            <div class="text-caption pt-2">Why rehoming the pet</div>
            <div class="text-caption black--text font-weight-bold">{{ pet.rehoming_reason }}</div>

        </div>
        </v-expand-transition>    
        
        </v-sheet>
        </div>

    

      <v-divider class="my-4" style="border-color: #eee"></v-divider>


      <v-row no-gutters>
                    <v-col>
                        <div class="text-overline grey--text">
                    Owner
                </div>
                   </v-col>
                <div class="pb-3 pt-2 mt-n2 d-flex flex-row align-center justify-end" v-if="pet.user.xid != this.$store.getters.userDetails.xid">
                   
                   <scam-report-button :user="pet.user"></scam-report-button>
                   <block-user-button :user="pet.user"></block-user-button>
                   
               </div>
            </v-row>

                

                <v-row no-gutters >
                    <v-col cols="2" class="d-flex align-start">
                        <v-avatar style="border: 2px solid #fff" color="rgba(126, 13, 231, 0.1)">
                            <v-img v-if="pet.user.photo" :src="pet.user.photo"></v-img>
                            <v-icon color="primary" v-else size="28">$vuetify.icons.account-icon</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="10" class="pl-2">
                        <div class="text-subtitle-2">{{pet.user.name}}
                            <!-- <v-icon small color="blue" v-show="pet.user.phone_verified && pet.user.email_verified">mdi-check-decagram</v-icon> -->
                        </div>
                        <div class="text-caption">
                            {{location}}
                        </div>


                        <v-row no-gutters class="py-1">
                            <v-col>
                                <v-chip v-if="pet.user.phone_verified" small label class="ma-1" color="#eef9e9" text-color="green">Phone Verified</v-chip>
                                <v-chip label v-if="pet.user.email_verified" small class="ma-1" color="#eef9e9" text-color="green">Email Verified</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                </v-row>


      



 

  </v-card-text>
</template>
<script>
import BlockUserButton from './BlockUser.vue';
import ScamReportButton from './ScamReport.vue';

export default {
  name: 'PetPop',
  props: ['value', 'pet', 'ownerPetId'],
  mounted() {
    //console.log('mounted PetPop')
  },
  components: {
    BlockUserButton,
    ScamReportButton
  },
  data() {
    return {
      moreInfo: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    location() {
      return (this.pet.locality ? this.pet.locality + ', ' : (this.pet.city ? this.pet.city + ', ' : '')) + this.pet.state + (this.pet.distance > 0 ? ' (' + Math.round(this.pet.distance) + ' Miles)' : '')
    },
    blockedUser() {
      return this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
        return o.buid;
      }) : [];
    }
  },
  methods: {
    showMoreInfo()
        {
            this.moreInfo = !this.moreInfo;
        },

        markFavourite() {
            let for_id = this.ownerPetId == null ? 'user' : this.ownerPetId;

            if (typeof (this.pet.fav_for) !== 'undefined') {
                for_id = this.pet.fav_for
            }

            let pet_id = this.pet.xid;

            this.favLoading = true;
            this.$store.dispatch('markFavorite', {
                    for_id,
                    pet_id,
                }).then(() => {
                    this.favLoading = false;
                    this.pet.is_favorite = !this.pet.is_favorite
                })
                .catch(err => {
                    this.favLoading = false;
                    //this.snackbar_text = "Oops, we encountered an error";
                    //this.snackbar = true;
                });

        },
  }
}
</script>
