<template>
  <v-dialog v-model="show" max-width="600" transition="dialog-transition" :retain-focus="false" persistent>

    <v-card class="petExtraInfoPop">

      <v-toolbar flat color="transparent" max-height="56">

        <v-toolbar-title class="text-subtitle-1 font-weight-bold black--text">{{ pet.name }}'s Profile </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click.stop="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-toolbar>
      <v-card-text style="min-height:220px">

        <v-progress-linear :value="progress" rounded height="8" color="primary"></v-progress-linear>

        <v-row class="my-2 ">

          <v-col cols="12" v-show="step == 1">
            <div class="primary--text text-subtitle-2 font-weight-600">Is the pet Microchipped
            </div>
            <v-radio-group v-model="is_microchipped" class="mt-2" hide-details @change="validateStep">
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 2">
            <div class="primary--text text-subtitle-2 font-weight-600">Is the pet house-trained?
            </div>
            <v-radio-group v-model="is_house_trained" class="mt-2"  hide-details @change="validateStep">
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 3">
            <div class="primary--text text-subtitle-2 font-weight-600">Does the pet have any known health issues? If so,
              please describe.</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder="" @input="validateStep"
              v-model="health_issues"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 4">
            <div class="primary--text text-subtitle-2 font-weight-600">Describe the pet's personality, energy level, and
              behavior with people and other animals.</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="temperament"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 5">
            <div class="primary--text text-subtitle-2 font-weight-600">Have you bred your pet before?</div>
            <v-radio-group v-model="previous_breeding" class="mt-2" row hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="previous_breeding === 1 && step == 5">
            <div class="primary--text text-subtitle-2 font-weight-600">If yes, how many times?</div>
            <v-text-field class="mt-2" v-model="breeding_count" dense outlined hide-details flat type="number"
              placeholder="0"></v-text-field>
          </v-col>

          <v-col cols="12" v-show="step == 6">
            <div class="primary--text text-subtitle-2 font-weight-600">Are you familiar with the breed standard and any
              genetic health issues associated with this breed?</div>
            <v-radio-group v-model="familiar_with_breed_standard" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 7">
            <div class="primary--text text-subtitle-2 font-weight-600">Have you consulted with a veterinarian about
              breeding your pet? </div>
            <v-radio-group v-model="consulted_veterinarian" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 8">
            <div class="primary--text text-subtitle-2 font-weight-600">Do you have a plan for caring for and placing the
              puppies/kittens? </div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="care_and_placement_plan"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 9">
            <div class="primary--text text-subtitle-2 font-weight-600">Is your pet up-to-date on vaccinations and
              preventive care? </div>
            <v-radio-group v-model="up_to_date_on_vaccinations" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 10">
            <div class="primary--text text-subtitle-2 font-weight-600">Has your pet been tested for any breed-specific
              health conditions or genetic disorders? </div>
            <v-radio-group v-model="has_health_tests" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="has_health_tests === 1 && step == 10">
            <div class="primary--text text-subtitle-2 font-weight-600">If yes, please provide details of the tests and
              results. </div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="health_test_results"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 11">
            <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to sign a breeding contract with
              the other pet owner? </div>
            <v-radio-group v-model="willing_to_sign_contract" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 12">
            <div class="primary--text text-subtitle-2 font-weight-600">Is the pet spayed/neutered?</div>
            <v-radio-group v-model="is_pet_spayed_neutered" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="is_pet_spayed_neutered === 0 && step == 12">
            <div class="primary--text text-subtitle-2 font-weight-600">If not, are you willing to have the pet
              spayed/neutered before sale?</div>
            <v-radio-group v-model="willing_to_spay_neuter" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" v-show="step == 13">
            <div class="primary--text text-subtitle-2 font-weight-600">Where did you get the pet?</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="pet_source"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 14">
            <div class="primary--text text-subtitle-2 font-weight-600">Describe the pet's current living environment –
              indoor/outdoor, type of housing, etc.</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="living_situation"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 15">
            <div class="primary--text text-subtitle-2 font-weight-600">What type of food do you feed the pet? How much
              exercise does it get daily?</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="diet_and_exercise"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 16">
            <div class="primary--text text-subtitle-2 font-weight-600">Be as detailed as possible about why you are
              rehoming the pet</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="rehoming_reason"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 17">
            <div class="primary--text text-subtitle-2 font-weight-600">Included with Sale: (Food, toys, crate, leash,
              etc.)</div>
            <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
              v-model="included_items"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="step == 18">
            <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to meet the buyer halfway for the
              transaction?</div>
            <v-radio-group v-model="willing_to_meet" class="mt-2" hide-details>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>




        </v-row>
      </v-card-text>
      <v-divider style="border-color:#eee"></v-divider>

      <v-card-actions class="py-3">
        <v-btn v-if="showPrevButton" color="gray" depressed @click="prevStep">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!isLastStep" color="primary" @click="nextStep" :disabled="!isStepValid">Next</v-btn>
        <v-btn v-if="isLastStep" color="primary" @click="submit" :disabled="!isStepValid">Submit</v-btn>
      </v-card-actions>

    </v-card>



    <v-snackbar v-model="snackbar" bottom>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-dialog>
</template>

<script>

export default {
  name: 'PetExtraInfo',
  props: ['value', 'pet'],
  mounted() {

  },
  data() {
    return {
      is_microchipped: this.pet.is_microchipped ? this.pet.is_microchipped : '',
      is_house_trained: this.pet.is_house_trained ? this.pet.is_house_trained : '',
      health_issues: this.pet.health_issues ? this.pet.health_issues : '',
      temperament: this.pet.temperament ? this.pet.temperament : '',
      previous_breeding: this.pet.previous_breeding ? this.pet.previous_breeding : '',
      breeding_count: this.pet.breeding_count ? this.pet.breeding_count : '',
      familiar_with_breed_standard: this.pet.familiar_with_breed_standard ? this.pet.familiar_with_breed_standard : '',
      consulted_veterinarian: this.pet.consulted_veterinarian ? this.pet.consulted_veterinarian : '',
      care_and_placement_plan: this.pet.care_and_placement_plan ? this.pet.care_and_placement_plan : '',
      has_health_tests: this.pet.has_health_tests ? this.pet.has_health_tests : '',
      health_test_results: this.pet.health_test_results ? this.pet.health_test_results : '',
      up_to_date_on_vaccinations: this.pet.up_to_date_on_vaccinations ? this.pet.up_to_date_on_vaccinations : '',
      veterinary_reference: this.pet.veterinary_reference ? this.pet.veterinary_reference : '',
      willing_to_sign_contract: this.pet.willing_to_sign_contract ? this.pet.willing_to_sign_contract : '',
      is_pet_spayed_neutered: this.pet.is_pet_spayed_neutered ? this.pet.is_pet_spayed_neutered : '',
      willing_to_spay_neuter: this.pet.willing_to_spay_neuter ? this.pet.willing_to_spay_neuter : '',
      pet_source: this.pet.pet_source ? this.pet.pet_source : '',
      living_situation: this.pet.living_situation ? this.pet.living_situation : '',
      diet_and_exercise: this.pet.diet_and_exercise ? this.pet.diet_and_exercise : '',
      rehoming_reason: this.pet.rehoming_reason ? this.pet.rehoming_reason : '',
      included_items: this.pet.included_items ? this.pet.included_items : '',
      willing_to_meet: this.pet.willing_to_meet ? this.pet.willing_to_meet : '',
      step: 1,

      breeding_steps: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      sale_steps: [1, 2, 3, 9, 12, 13, 14, 15, 16, 17, 18],
      friends_steps: [1, 2, 3, 4],


      submitting: false,
      snackbar: false,
      snackbar_text: '',
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    stepsArray() {
      return (this.pet.purpose === 'adopter' || this.pet.purpose === 'buyer') ? this.sale_steps : (this.pet.purpose === 'lover' ? this.breeding_steps : this.friends_steps );
    },
    showPrevButton() {
      return this.stepsArray.indexOf(this.step) > 0;
    },
    isLastStep() {
      return this.stepsArray.indexOf(this.step) === this.stepsArray.length - 1;
    },
    progress() {
      // Calculate progress as a percentage
      let currentIndex = this.stepsArray.indexOf(this.step);
      return ((currentIndex + 1) / this.stepsArray.length) * 100;
    },
    isStepValid() {
      // Check if the current step's fields are valid
      switch (this.step) {
        case 1:
          return this.is_microchipped !== '';
        case 2:
          return this.is_house_trained !== '';
        case 3:
          return this.health_issues.trim() !== '';
        case 4:
          return this.temperament.trim() !== '';
        case 5:
          return this.previous_breeding !== '';
        case 6:
          return this.familiar_with_breed_standard !== '';
        case 7:
          return this.consulted_veterinarian !== '';
        case 8:
          return this.care_and_placement_plan.trim() !== '';
        case 9:
          return this.up_to_date_on_vaccinations !== '';
        case 10:
          return this.has_health_tests !== '';
        case 11:
          return this.willing_to_sign_contract !== '';
        case 12:
          return this.is_pet_spayed_neutered !== '';
        case 13:
          return this.pet_source.trim() !== '';
        case 14:
          return this.living_situation.trim() !== '';
        case 15:
          return this.diet_and_exercise.trim() !== '';
        case 16:
          return this.rehoming_reason.trim() !== '';
        case 17:
          return this.included_items.trim() !== '';
        case 18:
          return this.willing_to_meet !== '';
        default:
          return false;
      }
    },
  },

  methods: {

    validateStep() {
      this.isStepValid; 
    },

    nextStep() {
      let currentIndex = this.stepsArray.indexOf(this.step);
      if (currentIndex !== -1 && currentIndex < this.stepsArray.length - 1) {
        this.step = this.stepsArray[currentIndex + 1];
      }
    },
    prevStep() {
      let currentIndex = this.stepsArray.indexOf(this.step);
      if (currentIndex > 0) {
        this.step = this.stepsArray[currentIndex - 1];
      }
    },

    submit() {
      this.submitting = true;

      let formData = new FormData();

      formData.append('id', this.pet.xid);
      formData.append('is_microchipped', this.is_microchipped);
      formData.append('is_house_trained', this.is_house_trained);
      formData.append('health_issues', this.health_issues);
      formData.append('temperament', this.temperament);
      formData.append('previous_breeding', this.previous_breeding);
      formData.append('breeding_count', this.breeding_count);
      formData.append('familiar_with_breed_standard', this.familiar_with_breed_standard);
      formData.append('consulted_veterinarian', this.consulted_veterinarian);
      formData.append('care_and_placement_plan', this.care_and_placement_plan);
      formData.append('up_to_date_on_vaccinations', this.up_to_date_on_vaccinations);
      formData.append('has_health_tests', this.has_health_tests);
      formData.append('health_test_results', this.health_test_results);
      formData.append('willing_to_sign_contract', this.willing_to_sign_contract);
      formData.append('is_pet_spayed_neutered', this.is_pet_spayed_neutered);
      formData.append('willing_to_spay_neuter', this.willing_to_spay_neuter);
      formData.append('pet_source', this.pet_source);
      formData.append('living_situation', this.living_situation);
      formData.append('diet_and_exercise', this.diet_and_exercise);
      formData.append('rehoming_reason', this.rehoming_reason);
      formData.append('included_items', this.included_items);
      formData.append('willing_to_meet', this.willing_to_meet);

      this.$store.dispatch('savePetExtraInfo', formData)
        .then((resp) => {
          this.$store.dispatch('getPetDetails', this.pet.xid).then((resp) => {
            this.submitting = false;
            this.$emit('force-pet-rerender');
            this.show = false;
          });
        })
        .catch(err => {
          this.snackbar_text = "Error while updating";
          this.snackbar = true;
          this.submitting = false;
        });
    },


  }
}
</script>